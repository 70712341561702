import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as moment from 'moment';
import { UserService } from '../user.service';
import { Subject } from 'rxjs';

import { map, filter } from 'rxjs/operators';



@Component({
  selector: 'app-adminuploadschedule',
  templateUrl: './adminuploadschedule.component.html',
  styleUrls: ['./adminuploadschedule.component.scss']
})
export class AdminuploadscheduleComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();



  CallsData: any;
  loginForm: FormGroup;
  regionData: any;
  stateData: any;
  branchData: any;
  action: any;
  status: any;
  serviceData: any;
  branch: any;
  selected: any;
  selectedstate: any;
  selectedbranch: any;
  selectedclient: any;
  dataTable: any;
  token: any;
  usertype: any;
  ufilename: any;
  logData: any;
  tablevisible: number;
  loader: boolean;
 

  constructor(public userService: UserService, private httpClient: HttpClient, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) {
    this.branch = localStorage.getItem('userBranch');
    this.token = localStorage.getItem('userToken');
    this.usertype = localStorage.getItem('userType');

  }


  viewcallList()
  {
    this.userService.viewUploadClientLog( this.branch).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.CallsData = data.data.logData;
          this.dtTrigger.next();
        }
      },
      (err: HttpErrorResponse) => {

      });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,
      processing: true
    }

    this.tablevisible=1;
    this.loader=true;
    this.userService.viewUploadClientLog( this.branch).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.CallsData = data.data.logData;
          this.dtTrigger.next();
          // this.chRef.detectChanges();
          // const table: any = $('table');
          // this.dataTable = table.DataTable();
          this.dtTrigger.next();
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {

      });
    //
    // this.userService.viewCalls('').then((data: any) => {
    //     if (data.status === 1) {
    //       this.CallsData = data.data.regionData;
    //
    //     }
    //
    //   },
    //   (err: HttpErrorResponse) => {
    //   //  this.isLoginError = true;
    //   });


    this.loginForm = this.formBuilder.group({
      region   : ['', [Validators.required]],
      state   : ['', [Validators.required]],
      branch   : ['', [Validators.required]]

    });
    this.action = 'save';
    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
          console.log(this.regionData);
        }


      },
      (err: HttpErrorResponse) => {

      });

    this.userService.viewCalls('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.serviceData = data.data.regionData;
        }
      },
      (err: HttpErrorResponse) => {

      });
  }

  onSubmit() {
    console.log(this.loginForm);
    this.uploadFileToActivity('');


  }

  fileToUpload: File = null;

  handleFileInput(files: FileList) {
    // alert("test1");
    this.fileToUpload = files.item(0);

  }

  postFile(fileToUpload: File): Observable<any> {
    console.log(fileToUpload.name);
    this.ufilename = fileToUpload.name
//    const endpoint = 'http://testinglink.in/demo/index.php/csv_import/import';

   //   const endpoint = 'https://testinglink.in/hvlbackend-new/index.php/client_import/import';
      const endpoint = 'http://app.hvlpestservices.com/backend/index.php/client_import/import';
      
      const formData: FormData = new FormData();
      formData.append('csv_file', fileToUpload, fileToUpload.name);
      formData.append('token', this.token);
      let headers = new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'No-Auth':'True'
      });


      // return this.httpClient.post(endpoint, formData, {headers: headers})
      // .map(data => {
      //     console.log(data);
      //     return data;

      //   });

      return this.httpClient.post(endpoint, formData, {headers: headers}).pipe(
    map(data => {
          console.log(data);
        return data;

        }));


  }


  uploadFileToActivity(branch) {
    this.postFile(this.fileToUpload).subscribe(data => {

      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Saved successfully.',
        showConfirmButton: false,
        timer: 1500
      })
      console.log(data);
this.status = data.status;
          if (this.status === 1) {
            swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500
            })
           // this.getBranchList();
            this.loginForm.reset();
          }
      // if (this.status === 1) {
      //   swal({
      //     position: 'top-end',
      //     type: 'success',
      //     title: 'Partially Uploaded.',
      //     showConfirmButton: false,
      //     timer: 1500
      //   })
      //   // this.getBranchList();
      //   this.loginForm.reset();
      // }
   //   alert('uploaded');
      this.loginForm.reset();
      this.viewcallList();
    }, error => {
      console.log(error);
              swal.fire({
          position: 'top-end',
          type: 'error',
          title: 'Upload Failed',
          showConfirmButton: false,
          timer: 1500
        })
    });
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
