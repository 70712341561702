import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { UserService} from "../user.service";
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import * as moment from 'moment';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-addbranchstaffs',
  templateUrl: './addbranchstaffs.component.html',
  styleUrls: ['./addbranchstaffs.component.scss']
})
export class AddbranchstaffsComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  delete_record:any;
  deleteid=[];
  public delele_sch = true
  loginForm: FormGroup;
  branchData: any;
  action: any;
  status: any;
  userData: any;
  editData: any;
  fname: any;
  lname:any;
  email: any;
  password: any;
  mobile: any;
  empid: any;
  selectedbranch: any;
  selectedadmin: any;
  userType : string;
  id: string;
  user_id:any;
  dataTable: any;
  basicExampleList = [
    {"id":"1","userType":"User"},
    {"id":"2","userType":"Manager"},
    {"id":"3","userType":"Admin"},
  ];
  tablevisible: number;
  loader: any;
  branchid: any;
  constructor(private userService: UserService, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.branchid=localStorage.getItem("userBranch");
    console.log(this.branchid);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }

    
    this.loginForm = this.formBuilder.group({
      fname   : ['', Validators.compose([Validators.required, Validators.pattern('[-_ a-zA-Z]+$'),  Validators.minLength(3), Validators.maxLength(30)])],
      lname   : ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*'),  Validators.minLength(3), Validators.maxLength(30)])],
      email   : ['', Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
      password   : ['', Validators.compose([Validators.required,  Validators.minLength(6), Validators.maxLength(30)])],
      mobile  : ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
      empid   : ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9 ]*'),   Validators.maxLength(220)])],
      usertype   : ['', [Validators.required]]

    });

    // this.userService.viewBranch1('').then((data: any) => {
    //     console.log(data);
    //     if (data.status === 1) {
    //       this.branchData = data.data.branchData;

    //     }
    //   },
    //   (err: HttpErrorResponse) => {

    //   });
this.tablevisible=1;
this.loader=true;
    this.userService.getBranchStaffs(this.branchid).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.userData = data.data.logData;
          this.dtTrigger.next();
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {

      });
    this.action = 'save';
  }

  getUserList() {
    this.userService.getBranchStaffs(this.branchid).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.userData = data.data.logData;

        }
      },
      (err: HttpErrorResponse) => {
    });
  }
  async funDelete(user_id) {
    await  swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        this.userService.deleteStaff(user_id).then(async (data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
            this.getUserList();
            await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
          }

        },
        async (err : HttpErrorResponse) => {
          await  swal.fire("Cancelled", "Something went wrong,Please try again later :)", "error");
        });
      }else{
        await  swal.fire("Cancelled", "Items cannot be deleted :)", "error");
      }

    });
  }


  newdelete = false;
  onDeleteChange(i,data,event) {
    this.delele_sch = false ;
    this.delete_record = data ;
    let index = this.deleteid.indexOf(this.delete_record.user_id);
    if (index === -1) {
        this.deleteid.push(this.delete_record.user_id);
        this.delete_record.selected = true;
    } else {
        this.deleteid.splice(index,1);
        this.delete_record.selected = false;
    }
    console.log(this.deleteid);
  }
  async DeleteMultipleRecord(){

    await swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        for(let i=0;i<this.deleteid.length;i++){
          this.userService.deleteUser(this.deleteid[i]).then(async (data : any)=>{
            this.status = data.status;
            if (data.status === 1) {
              this.getUserList();
              await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
            }    
          });
    
        }
      }else{
        await  swal.fire("Cancelled", "Items cannot be deleted :)", "error");
      }

    });



  }




  funEdit(index){
    const editData= this.userData[index];
    this.fname = editData.firstname;
    this.lname = editData.lastname;
    this.email = editData.email;
    this.password = editData.password;
    this.mobile = editData.mobile;
    this.empid = editData.empid;
    this.selectedbranch = editData.branch_id;
this.selectedadmin = editData.role;
    this.user_id = editData.id;
    this.action = 'edit';
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }


  onSubmit() {
    this.loginForm.value.fname=this.loginForm.value.fname.trim();
    this.loginForm.value.lname=this.loginForm.value.lname.trim();this.loginForm.value.email=this.loginForm.value.email.trim();
    this.loginForm.value.empid=this.loginForm.value.empid.trim();this.loginForm.value.mobile=this.loginForm.value.mobile.trim();
   
    console.log(this.loginForm.value);
    if (this.loginForm.valid) {
      console.log(this.loginForm.value);
      if(this.action === 'save'){
        this.loginForm.value.password=this.loginForm.value.password.trim();
        this.userService.addNewStaffs(this.branchid,this.loginForm.value.fname, this.loginForm.value.lname, this.loginForm.value.email, this.loginForm.value.mobile, this.loginForm.value.empid, this.loginForm.value.usertype, this.loginForm.value.password).then((data : any)=>{
            console.log(data);
            this.status= data.status;
            if (data.status === 1)
            this.loginForm.reset()
            {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getUserList();
              this.rerender();
              this.loginForm.reset();
            }


          },
          (err: HttpErrorResponse) => {
            console.log('error');
          });
      }
      if(this.action === 'edit'){
        this.userService.editStaff(this.loginForm.value.fname, this.loginForm.value.lname, this.loginForm.value.email, this.loginForm.value.mobile, this.loginForm.value.empid, this.branchid, this.loginForm.value.usertype, this.user_id ).then((data : any)=>{
            console.log(data);



            this.status = data.status;
            if (data.status === 1) {
              this.getUserList();
              this.loginForm.reset()
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
            }
            else if (data.status === 0)

            {
              swal.fire({
                position: 'top-end',
                type: 'warning',
                title: 'Already Exists.',
                showConfirmButton: false,
                timer: 1500
              })

              this.getUserList();
              this.rerender();
              // this.reset();
            }
          },
          (err : HttpErrorResponse) => {
            //this.isLoginError = true;
          });
      }

      console.log(this.loginForm.value.region);
    }

  }
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
