import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminstudentComponent } from './adminstudent/adminstudent.component';
import { UploadscheduleComponent } from './uploadschedule/uploadschedule.component';
import { ViewscheduleComponent } from './viewschedule/viewschedule.component';
import { ViewcompletedComponent } from './viewcompleted/viewcompleted.component';
import { CreateclientComponent } from './createclient/createclient.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ClientuploadComponent } from './clientupload/clientupload.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { RegionComponent } from './region/region.component';
import { StateComponent } from './state/state.component';
import { BranchComponent } from './branch/branch.component';
import { AdminuploadscheduleComponent } from './adminuploadschedule/adminuploadschedule.component';
import { AdminnewscheduleComponent } from './adminnewschedule/adminnewschedule.component';
import { AdminviewscheduleComponent } from './adminviewschedule/adminviewschedule.component';
import { AdminviewcompletedComponent } from './adminviewcompleted/adminviewcompleted.component';
import { ClientviewscheduleComponent } from './clientviewschedule/clientviewschedule.component';
import { ClientviewcompletedComponent } from './clientviewcompleted/clientviewcompleted.component';
import { AdduserComponent } from './adduser/adduser.component';
import { OperationComponent } from './operation/operation.component';
import { BillingfrequencyComponent } from './billingfrequency/billingfrequency.component';
import { ClientdashboardComponent } from './clientdashboard/clientdashboard.component';
import { AdmincreateclientComponent } from './admincreateclient/admincreateclient.component';
import { AdminclientuploadComponent } from './adminclientupload/adminclientupload.component';
import { AuthguardService } from './authguard.service';
import { UserAuthguardService } from './user-authguard.service';
import { AddstaffsComponent } from './addstaffs/addstaffs.component';
import { AddauditscheduleComponent } from './addauditschedule/addauditschedule.component';
import { AddbranchstaffsComponent } from './addbranchstaffs/addbranchstaffs.component';
import { AddbranchscheduleComponent } from './addbranchschedule/addbranchschedule.component';

const routes: Routes = [
  { path:'',component: LoginComponent },
  { path:'login',component: LoginComponent },
  { path:'dashboard',component: DashboardComponent , canActivate:[UserAuthguardService] },
  { path:'newschedule',component: AdminstudentComponent, canActivate:[UserAuthguardService]  },
  { path:'uploadschedule',component: UploadscheduleComponent , canActivate:[UserAuthguardService] },
  { path:'viewschedule',component: ViewscheduleComponent, canActivate:[UserAuthguardService] },
  { path:'viewcompleted',component: ViewcompletedComponent, canActivate:[UserAuthguardService] },
  { path:'createclient',component: CreateclientComponent, canActivate:[UserAuthguardService] },
  { path:'schedule',component: ScheduleComponent, canActivate:[UserAuthguardService] },
  { path:'clientupload',component:ClientuploadComponent, canActivate:[UserAuthguardService] },
  {path:'newbranchstaff',component:AddbranchstaffsComponent, canActivate:[UserAuthguardService] },
  {path:'manageschedule',component:AddbranchscheduleComponent, canActivate:[UserAuthguardService] },

  { path:'dashboard1',component: AdmindashboardComponent , canActivate:[AuthguardService] },
  { path:'adminuploadschedule',component: AdminuploadscheduleComponent, canActivate:[AuthguardService] },
  { path:'region',component: RegionComponent, canActivate:[AuthguardService] },
  { path:'state',component: StateComponent, canActivate:[AuthguardService] },
  { path:'branch',component: BranchComponent, canActivate:[AuthguardService] },
  { path:'operation',component: OperationComponent, canActivate:[AuthguardService] },
  { path:'billingfrequency',component: BillingfrequencyComponent, canActivate:[AuthguardService] },
  { path:'adminnewschedule', component:AdminnewscheduleComponent, canActivate:[AuthguardService] },
  { path:'adminviewschedule', component:AdminviewscheduleComponent, canActivate:[AuthguardService] },
  { path:'adminviewcomplete', component:AdminviewcompletedComponent, canActivate:[AuthguardService] },
  { path:'adduser', component:AdduserComponent, canActivate:[AuthguardService] },
  { path:'admincreateclient', component:AdmincreateclientComponent, canActivate:[AuthguardService] },
  { path:'adminclientupload', component:AdminclientuploadComponent, canActivate:[AuthguardService] },
  { path:'addstaffs',component:AddstaffsComponent, canActivate:[AuthguardService] },
  { path:'addauditschedule',component:AddauditscheduleComponent, canActivate:[AuthguardService] },

  { path:'clientviewschedule', component:ClientviewscheduleComponent},
  { path:'clientdashboard', component:ClientdashboardComponent},
  { path:'clientviewcompleted', component:ClientviewcompletedComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
