import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public route: any;
  pagetitle: string;
public logo = 'logo.png'
  public type: any;
  branchsession:any;
  
  constructor(public router: Router ,public location:Location){
   
    this.type = localStorage.getItem('userType');
    this.branchsession = localStorage.getItem('branchname');
  //  alert(this.type);
    router.events.subscribe((val) => {
      this.route = location.path();
     console.log(this.route);
     

     if (this.type==2)
     {
      if(this.route=="/dashboard"){
        this.pagetitle = "Dashboard"
      }
      if(this.route=="/newschedule"){
        this.pagetitle = "New Schedule"
      }
      if(this.route=="/viewschedule"){
        this.pagetitle = "View Schedules"
      }
      if(this.route=="/viewcompleted"){
        this.pagetitle = "View Completed"
      }
      if(this.route=="/uploadschedule"){
        this.pagetitle = "Upload Schedule"
      }
      if(this.route=="/createclient"){
        this.pagetitle = "Add Clients"
      }
      if(this.route=="/clientupload"){
        this.pagetitle = "Upload Client"
      }
      if(this.route=="/schedule"){
        this.pagetitle = " Generate Schedule"
      }
      if(this.route=="/region"){
        this.pagetitle = " Region"
      }
      if(this.route=="/state"){
        this.pagetitle = " State"
      }
      if(this.route=="/branch"){
        this.pagetitle = " Branch"
      }
     }

    
     if (this.type==1)
     {



    if(this.route=="/adminuploadschedule"){
      this.pagetitle = " Admin Upload "
    }
    if(this.route=="/adminnewschedule"){
      this.pagetitle = " Admin New Schedule"
    }
    if(this.route=="/adminviewschedule"){
      this.pagetitle = " Admin View Schedules"
    }
    if(this.route=="/adminviewcomplete"){
      this.pagetitle = " Admin View Completed"
    }
    if(this.route=="/billingfrequency"){
      this.pagetitle = " Treatment Frequency"
    }
    if(this.route=="/adduser"){
      this.pagetitle = " Add User"
    }
    if(this.route=="/admincreateclient"){
      this.pagetitle = "Create Client"
    }
    if(this.route=="/operation"){
      this.pagetitle = " Operation"
    }
  }


  });
  }


  
LogoutClick(){
  localStorage.clear();
  this.router.navigate(['/login']);
}
  
}
