import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { UserService } from '../user.service';
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { FormGroup,FormBuilder,Validators,FormControl,NgForm} from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-clientdashboard',
  templateUrl: './clientdashboard.component.html',
  styleUrls: ['./clientdashboard.component.scss']
})
export class ClientdashboardComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  loginForm1: FormGroup;
  serviceData: any;
  status: any;
  service_id: any;
  branch: any;
  retrievedData: any;
  displayservice: any;
  completedCount: any;
  pendingCount: any;
  usertype: any;
  token: any;
  dataTable: any;
  branchData:any;
  loader:any;
  localTime:any;
  date:any;
  localTime1:any;
  date1:any;
  tablevisible:any;
  NorthcompletedCount: any;
  southcompletedCount: any;
  southpendingCount: any;
  NorthpendingCount: any;
  eastpendingCount: any;
  eastcompletedCount: any;
  westcompletedCount: any;
  westpendingCount: any;



  constructor(private userService: UserService, private formBuilder: FormBuilder,
     private httpClient: HttpClient,  private chRef: ChangeDetectorRef,
     public dialog: MatDialog,
     ) { 
    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    console.log(this.branch);

  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }

    this.loginForm1 = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],

    });
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
    
    // this.loginForm = this.formBuilder.group({
    //   fromdate: ['', [Validators.required]],
    //   todate: ['', [Validators.required]],

    // });

    this.tablevisible=1;
    this.loader=true;
    if(this.usertype == 1) {
      this.userService.viewCallsByPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallsByBranchPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }


    else if(this.usertype == 3){
      this.tablevisible=1;
      this.userService.viewCallsByClientPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();

            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }



    // this.userService.serviceCount( this.branch).then((data: any) => {
    //     console.log(data);
    //     if (data.status === 1) {
    //      // this.serviceData = data.data.serviceData;
    //
    //     }
    //   },
    //   (err: HttpErrorResponse) => {
    //
    //   });
    if(this.usertype == 2) {
      this.userService.viewGetCount('').then((data: any) => {
          console.log(data);
          this.completedCount = data.data.completedCount;
          console.log(this.completedCount);
          this.pendingCount = data.data.pendingCount;
          console.log(this.pendingCount);

        },
        (err: HttpErrorResponse) => {

        });
    }

    if(this.usertype == 3) {
      this.userService.viewGetCount_client(this.branch).then((data: any) => {
          console.log(data);
          this.NorthcompletedCount = data.data.northCount_complete;
          this.NorthpendingCount = data.data.northCount;

          this.southcompletedCount = data.data.southCount_complete;
          this.southpendingCount = data.data.southCount;

          this.eastpendingCount = data.data.eastCount;
          this.eastcompletedCount = data.data.eastCount_complete;
     
          this.westpendingCount = data.data.westCount;
          this.westcompletedCount = data.data.westCount_complete;
       

        },
        (err: HttpErrorResponse) => {

        });
    }

    

  }



// {
//   this.dtTrigger.next();
// }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  exporttoexcel()
  {

 //   alert(this.fileName);
    console.log(this.serviceData);

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'client_list.xls', { bookType: 'xls', type: 'buffer' });


    // /* generate worksheet */
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.clientData);
    //
    // /* generate workbook and add the worksheet */
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    // /* save to file */
    // XLSX.writeFile(wb, this.fileName);
  }



  searchrecord()
  {
    this.localTime  = moment.utc(this.loginForm1.value.fromdate).toDate();
    this.date = moment(this.localTime).format('YYYY-MM-DD');

    this.localTime1  = moment.utc(this.loginForm1.value.todate).toDate();
    this.date1 = moment(this.localTime1).format('YYYY-MM-DD');
 
    if ((this.loginForm1.value.fromdate!=undefined) && (this.loginForm1.value.todate!=undefined))
    {
    this.userService.viewCalls_date(this.branch,this.date,this.date1).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
     //   alert("test");
     this.tablevisible=1;
     this.dataTable.destroy();
        this.serviceData = data.data.serviceData;
        // this.chRef.detectChanges();
        this.dtTrigger.next();
        // const table: any = $('table');
        // this.dataTable = table.DataTable();
        this.loader=false;
      }
    },
    (err: HttpErrorResponse) => {

    });
  }
  }

  funEdit(index) {
    const editData = this.serviceData[index];
    this.service_id = editData.service_id;
    swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm!'
    }).then((result) => {

      this.userService.viewServicesDashboard(this.service_id,'').then((data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
       //     this.getServicesList();
            location.reload();
          }

        },
        (err : HttpErrorResponse) => {
        });
    })
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }




  // openDialog(index) {
  //   const editData = this.serviceData[index];
  //   this.service_id = editData.service_id;

  //   const dialogRef = this.dialog.open(ModelComponent, {
  //     height: '45%',
  //     data: { id: this.service_id}


  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     this.getServicesList();
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }


  getServicesList(){
    
    if(this.usertype == 1) {
      this.userService.viewCallsByPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallByBranch(this.token).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }
  }








}
