import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../user.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: any;
  pass: any;
  isLoginError: boolean = false;
  status: any;
  errormessage: any;
  public logo = 'logo.png'
  constructor(public router:Router,
    public userservice:UserService
    ) { }

  ngOnInit() {
  }
  LoginClick(){
    console.log(this.username);
    console.log(this.pass);
    this.userservice.loginApi(this.username,this.pass).then((res: any) => {
      this.status = res.status;
 
      if (this.status === 0 ){
        this.errormessage = 'Invalid Username or Password';
      }
      if (res.status === 1) {
        this.localVarCreate(res).then((success) => {
          
          localStorage.setItem('userdata', res.data);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('usertype', res.data.usertype);
          localStorage.setItem('branchname', res.data.branchname);


         if (res.data.usertype=="1")
         {
          localStorage.setItem('adminin',res);
          this.router.navigate(['/dashboard1']);
         }
         if (res.data.usertype=="2")
         {
          localStorage.setItem('userin',res);
          this.router.navigate(['/dashboard']);
         }
         if (res.data.usertype=="3")
         {
          localStorage.setItem('clientin',res.token);
          this.router.navigate(['/clientdashboard']);
         }

        });
      }
    },
    (err: HttpErrorResponse) => {
      this.isLoginError = true;
    });

  }


  localVarCreate(data) {
    return new Promise((resolve) => {
      localStorage.setItem('userToken', data.data.token);
      localStorage.setItem('userBranch', data.data.branch);
      localStorage.setItem('userFname', data.data.fname);
      localStorage.setItem('userLname', data.data.lname);
      localStorage.setItem('userType', data.data.usertype);
      localStorage.setItem('userEmail', data.data.user);
      resolve('success');
    });
  }


}
