import { Component, OnInit ,AfterViewInit,PipeTransform, OnDestroy,Pipe, ViewChild,ChangeDetectorRef,ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../user.service';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import * as moment from 'moment';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-createclient',
  templateUrl: './createclient.component.html',
  styleUrls: ['./createclient.component.scss']
})
export class CreateclientComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  delete_record:any;
  deleteid=[];
  public delele_sch = true;
  status: any;

  loginForm: FormGroup;
  branchData: any;
  action: any;
  clientData: any;
  cname: any;
  contactname: any;
  billingaddress: any;
  city: any;
  email: any;
  mobile: any;
  client_id: any;
  cid: any;
  gstnumber: any;
  pincode: any;
  shippingaddress: any;
  selectedbranch: any;
  clientcode: any;
  dataTable: any;
  branch: any;
  token: any;
  usertype: any;
  selbranch: any;
  clientcatData: any;
  selectedcategory: any;
  category: any;
  fileName: string = 'SheetJS.xlsx';
  loader: boolean;
  tablevisible: number;


  constructor(private userService: UserService, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef, private el: ElementRef,private location: Location) { this.branch = localStorage.getItem('userBranch');
  this.token = localStorage.getItem('userToken');
  this.usertype = localStorage.getItem('userType'); }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }

if (this.usertype==1)
{
  this.loginForm = this.formBuilder.group({
    cname   : ['', Validators.compose([Validators.required, Validators.pattern('[-_ a-zA-Z]+$'),  Validators.minLength(3), Validators.maxLength(30)])],
    cid : ['', [Validators.required]],
    gstnumber   : ['', [Validators.required]],
    shippingaddress : ['', [Validators.required]],
    billingaddress   : ['', [Validators.required]],
    contactname   : ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*'),  Validators.minLength(3), Validators.maxLength(30)])],
    city :['', [Validators.required]],
    email   : ['', Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    mobile :['', [Validators.required]],
    branch :['', [Validators.required]],
    category :['', [Validators.required]],
    pincode :['', [Validators.required]]


  });
}
    if (this.usertype==2)
    {
      this.loginForm = this.formBuilder.group({
        cname   : ['', [Validators.required]],
        cid : ['', [Validators.required]],
        gstnumber   : ['', [Validators.required]],
        shippingaddress : ['', [Validators.required]],
        billingaddress   : ['', [Validators.required]],
        contactname   : ['', [Validators.required]],
        city :['', [Validators.required]],
        email   : ['', Validators.compose([Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
        mobile :['', [Validators.required]],
        category :['', [Validators.required]],
        pincode :['', [Validators.required]]


      });
    }

    this.userService.viewBranch1('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchData = data.data.branchData;

        }
      },
      (err: HttpErrorResponse) => {

      });

    this.userService.viewCategory('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.clientcatData = data.data.clientcatData;
console.log(this.clientcatData);
this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {

      });

      this.tablevisible=1;
      this.loader=true;

    if (this.usertype == 1) {
      this.userService.viewClientList('').then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.loader=false;
            this.clientData = data.data.clientData;
            this.dtTrigger.next();
            
          }
        },
        (err: HttpErrorResponse) => {

        });
    }

    if (this.usertype == 2) {
      this.userService.viewClientList_user(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.loader=false;
            this.clientData = data.data.clientData;
           
             this.dtTrigger.next({
               buttons: [
                 'copy', 'csv', 'excel', 'pdf', 'print'
               ],

            } );
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    this.action = 'save';
  }


  exporttoexcel()
  {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.clientData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'client_list.xls', { bookType: 'xls', type: 'buffer' });
  }
  funEdit(index) {
    window.scrollTo(0, 0);
    const editData = this.clientData[index];
    console.log(editData);
    this.cname = editData.client_name;
    console.log(this.cname);
    this.contactname = editData.contact_person;
    this.billingaddress = editData.address;
   this.city = editData.city;
   this.email =editData.email;
   this.mobile = editData.mobile;
  this.cid =editData.clientcode;
  this.gstnumber =editData.gstno;
  this.pincode =editData.pincode;
  this.shippingaddress=editData.shippingaddress;
  this. selectedbranch=editData.branch_id;
    this. selectedcategory=editData.category;
    this.client_id = editData.client_id;
    this.action = 'edit';

  }
  funDelete(clientid)
  {
    swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      this.userService.deleteClient(clientid).then((data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
            swal.fire({
              position: 'top-end',
              type: 'success',
              title: 'Deleted successfully.',
              showConfirmButton: false,
              timer: 1500
            })
            this.getClientList();
          }

        },
        (err : HttpErrorResponse) => {
        });
    });

  }


  getClientList()
  {
    if (this.usertype == 1) {
      this.userService.viewClientList('').then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.clientData = data.data.clientData;
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }

    if (this.usertype == 2) {
      this.userService.viewClientList_user(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.clientData = data.data.clientData;
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
  }


  onSubmit() {


    if (this.loginForm.valid) {

      if (this.usertype==1)
      {
        this.selbranch = this.loginForm.value.branch;
      }
      if (this.usertype==2)
      {
        this.selbranch = this.branch;
      }
      if (this.action === 'save') {
        this.userService.addClients(this.loginForm.value.cname, this.loginForm.value.contactname, this.loginForm.value.email, this.loginForm.value.billingaddress, this.loginForm.value.mobile, this.selbranch, this.loginForm.value.city, this.loginForm.value.gstnumber, this.loginForm.value.shippingaddress, this.loginForm.value.pincode, this.loginForm.value.cid, this.loginForm.value.category).then((data : any)=> {
            console.log(data);
            this.status = data.status;
            if (data.status === 1)
            {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getClientList();
              this.loginForm.reset();
            }


          },
          (err: HttpErrorResponse) => {
            console.log('error');
          });
      }
      if (this.action === 'edit') {


// console.log("One======>" + this.loginForm.value.cname);
// console.log("Two======>" + this.loginForm.value.contactname);
// console.log("Three======>" + this.loginForm.value.email);
// console.log("Four======>" + this.loginForm.value.billingaddress);
// console.log("Five======>" + this.loginForm.value.mobile);
// console.log("Six======>" + this.selbranch);
// console.log("Seven======>" + this.loginForm.value.city);
// console.log("Eight======>" + this.client_id);
// console.log("Nine======>" + this.loginForm.value.gstnumber);
// console.log("Ten======>" + this.loginForm.value.shippingaddress);
// console.log("leven======>" + this.loginForm.value.pincode);
// console.log("Twele======>" + this.loginForm.value.cid);
// console.log("thrieden======>" + this.loginForm.value.category);


        if (this.usertype==1)
        {
          this.selbranch = this.loginForm.value.branch;
        }
        if (this.usertype==2)
        {
          this.selbranch = this.branch;
        }
        this.userService.editClients(this.loginForm.value.cname, this.loginForm.value.contactname, this.loginForm.value.email, this.loginForm.value.billingaddress, this.loginForm.value.mobile, this.selbranch, this.loginForm.value.city, this.client_id, this.loginForm.value.gstnumber, this.loginForm.value.shippingaddress, this.loginForm.value.pincode, this.loginForm.value.cid, this.loginForm.value.category,'').then((data : any)=>{
            console.log(data);
            this.status = data.status;
            if (data.status === 1) {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              // location.reload();
              this.getClientList();
          
            }
            else if (data.status === 0)

            {
              swal.fire({
                position: 'top-end',
                type: 'warning',
                title: 'Already Exists.',
                showConfirmButton: false,
                timer: 1500
              })

              this.getClientList();
              this.loginForm.reset();
            }
          },
          (err : HttpErrorResponse) => {
          });
      }

      // console.log(this.loginForm.value.region);
    }

  }

  newdelete = false;
  onDeleteChange(i,data,event) {
    this.delele_sch = false ;
    console.log(data);
    this.delete_record = data ;
    // this.deleteid.push(this.delete_record);
    let index = this.deleteid.indexOf(this.delete_record.client_id);
    if (index === -1) {
        this.deleteid.push(this.delete_record.client_id);
        this.delete_record.selected = true;
    } else {
        this.deleteid.splice(index,1);
        this.delete_record.selected = false;
    }
console.log(this.deleteid);

  }
  DeleteMultipleRecord(){
  console.log(this.deleteid);
    for(let i=0;i<this.deleteid.length;i++){
      console.log("Delete Id of "+this.deleteid[i]);
      this.userService.deleteClient(this.deleteid[i]).then((data : any)=>{
        this.status = data.status;
        if (data.status === 1) {
          swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Deleted successfully.',
            showConfirmButton: false,
            timer: 1500
          })
          this.getClientList();
        }

      });

      // this.userService.deleteSerivce(this.deleteid[i]).then((data : any)=>{
      //   this.status = data.status;
      //   location.reload();
      //   this.delele_sch = true ;
      //   if (data.status === 1) {
      //     // this.getServicesList();
      //   }

      // });


    }
  }


  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

rerender(): void {
this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  dtInstance.destroy();
  this.dtTrigger.next();
});
}

}
