import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef,Inject } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { UserService } from '../user.service';
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import * as moment from 'moment';


import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';

export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-viewschedule',
  templateUrl: './viewschedule.component.html',
  styleUrls: ['./viewschedule.component.scss']
})
export class ViewscheduleComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  animal: string;
  name: string; 



  serviceData: any;
  branch: any;
  usertype: any;
  token: any;
  dataTable: any;
  loader:any;
  loginForm: FormGroup;
  localTime:any;
  date:any;
  localTime1:any;
  date1:any;
  tablevisible:any;
  branchData:any;
  treatmentdate: any;
  treatment_id: any;
  todate: string;
  fromdate: string;
  constructor(private userService: UserService,  private formBuilder: FormBuilder,
     private chRef: ChangeDetectorRef,
     public dialog: MatDialog
     ) { 
    this.branch = localStorage.getItem('userBranch');
  }

  ngOnInit() {
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }


    this.loginForm = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],

    });

    
    this.userService.viewBranch1('').then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.branchData = data.data.branchData;

      }
    },
    (err: HttpErrorResponse) => {

    });
    
    this.tablevisible=1;
    this.loader=true;
    if(this.usertype == 1) {
      this.userService.viewCalls(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallByBranch(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.dtTrigger.next();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 3){
      this.userService.viewCallsByClientPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            console.log(this.serviceData);
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.dtTrigger.next();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }



  }
  onChange(event)
  {
    
    this.branch = event.value;
  }

  searchrecord()
  {
    this.localTime  = moment.utc(this.loginForm.value.fromdate).toDate();
    this.date = moment(this.localTime).format('DD-MM-YYYY');

    this.localTime1  = moment.utc(this.loginForm.value.todate).toDate();
    this.date1 = moment(this.localTime1).format('DD-MM-YYYY');
    
if ((this.loginForm.value.fromdate!=undefined) && (this.loginForm.value.todate!=undefined))
{
  this.userService.viewCalls_date(this.branch,this.date,this.date1).then((data: any) => {
      
    if (data.status === 1) {
   this.tablevisible=1;
      this.serviceData = data.data.serviceData;
      this.rerender()
      this.loader=false;
    }

  },
  (err: HttpErrorResponse) => {

  });
}
// else if (this.usertype=="3")
// {
//   this.userService.viewCalls_date_client(this.branch,this.date,this.date1).then((data: any) => {
      
//     if (data.status === 1) {
//    //   alert("test");
//    this.tablevisible=1;
//   //  this.dataTable.destroy();
//       this.serviceData = data.data.serviceData;
//       // this.chRef.detectChanges();
     
//       // const table: any = $('table');
//       // this.dataTable = table.DataTable();
//       this.dtTrigger.next();
//       this.loader=false;
//     }
//   },
//   (err: HttpErrorResponse) => {

//   });
// } 
  }

  exporttoexcel()
  {
 

 //   alert(this.fileName);
    console.log(this.serviceData);

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'client_list.xls', { bookType: 'xls', type: 'buffer' });


    // /* generate worksheet */
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.clientData);
    //
    // /* generate workbook and add the worksheet */
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    // /* save to file */
    // XLSX.writeFile(wb, this.fileName);
  }




  openDialog(treatment_id,treatment): void {
    console.log(treatment_id);
    console.log(treatment);
    this.treatmentdate = treatment ;
    this.treatment_id = treatment_id ;
    
    const dialogRef = this.dialog.open(setcompleteDialog, {
      width: '250px',
      data: {treatment_id:this.treatment_id,treatmentdate: this.treatmentdate}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
      this.datatable();
    });
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

rerender(): void {
this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  dtInstance.destroy();
  this.dtTrigger.next();
});
}


clearfilterOption(){
  // this.todate = '',
  // this.fromdate = '',
  this.loginForm.reset();
  this.datatable();
  }
  datatable(){
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
    this.tablevisible=1;
    this.loader=true;
    if(this.usertype == 1) {
      this.userService.viewCalls(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.rerender();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallByBranch(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.chRef.detectChanges();
            // const table: any = $('table');
            // this.dataTable = table.DataTable();
            this.rerender();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 3){
      this.userService.viewCallsByClientPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            console.log(this.serviceData);
            this.rerender();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }

  }




}




@Component({
  selector: 'setcompletepopup',
  templateUrl: 'setcompletepopup.html',
})
export class setcompleteDialog {
  selectdate = new Date();
  selecteddate: any;
  selecteddate_current: string;
  serviceData: any;
  tablevisible: number;
  loader: boolean;
  usertype: number;
  constructor(
    public dialogRef: MatDialogRef<setcompleteDialog>,
    private userService: UserService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


  SetoperationDate(data){
    this.dialogRef.close();
    console.log(data)
    console.log(this.selecteddate)
    // let wrongdate = "00-00-0000";
// if(!wrongdate){

  this.selecteddate_current = moment(this.selecteddate).format('YYYY-MM-DD');
  console.log(this.selecteddate_current)
  this.userService.viewServicesDashboard(data,this.selecteddate_current).then((data : any)=>{
    console.log(data);

    if (data.status === 1) {
    }

  });
// }  

  }

}