import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {FormGroup,FormBuilder,Validators,FormControl,NgForm} from '@angular/forms';
import 'datatables.net';
import 'datatables.net-bs4';
import { UserService } from '../user.service';
import { Subject,Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import {map, startWith} from 'rxjs/operators';


@Component({
  selector: 'app-adminstudent',
  templateUrl: './adminstudent.component.html',
  styleUrls: ['./adminstudent.component.scss']
})
export class AdminstudentComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loginForm: FormGroup;
  loginForm1: FormGroup;
  regionData: any;
  stateData: any;
  branchData: any;
  action: any;
  status: any;
  mapData: any;
  clientData=[];
  
  operationData: any;
  localTime: any;
  date: any;
  serviceData: any;
  selected: any;
  selectedstate: any;
  selectedbranch: any;
  selectedclient: any;
  sitename:any;
  selectedoperation: any;
  servicedate : any;
  technician : any;
  mobile: any;
  clienttype: any;
  servicestatus: any;
  selectedfrequency: any;
  selectedstatus: any;
  service_id : any;
  billingData: any;
  branch : any;
  ponumber: any;
  value: any;
  userType : string;
  id: string;
  statusmodel: any;
  servicedatemodel: any;
  usertype: any;
  token: any;
  dataTable: any;
  loader:any;

  localTime1:any;
  date1:any;
  tablevisible:any;
  statusList = [
    {"id":"0","userType":"select"},
    {"id":"1","userType":"Pending"},
    {"id":"2","userType":"Completed"}
  ];
  delete_record:any;
  deleteid=[];
  public delele_sch = true;
  fromdate: string;
  todate: string;

  myControl = new FormControl();
  // options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;

  constructor(private location: Location,private userService: UserService, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef, private router: Router) {
    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
   }


  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }
    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
    this.loginForm1 = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],
    });
    this.loader=true;
    if (this.usertype == 1)
    {
      this.loginForm = this.formBuilder.group({
        clienttype: ['', [Validators.required]],
        region: ['', [Validators.required]],
        state: ['', [Validators.required]],
        branch: ['', [Validators.required]],
        client: ['', [Validators.required]],
        operation: ['', [Validators.required]],
        frequency: ['', [Validators.required]],
        servicestatus: ['', [Validators.required]],
        servicedate: ['', [Validators.required]],
        technician: ['', [Validators.required]],
        mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
        sitename: ['', [Validators.required]],
        ponumber: ['', [Validators.required]],
        value: ['', [Validators.required]]
      });
    }

    if (this.usertype == 2)
    {
      this.loginForm = this.formBuilder.group({
        clienttype: ['', [Validators.required]],
        client: ['', [Validators.required]],
        operation: ['', [Validators.required]],
        frequency: ['', [Validators.required]],
        servicestatus: ['', [Validators.required]],
        servicedate: ['', [Validators.required]],
        technician: ['', [Validators.required]],
        mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
        sitename: ['', [Validators.required]],
        ponumber: ['', [Validators.required]],
        value: ['', [Validators.required]]
      });
    }

    this.action = 'save';
    this.userService.viewFrequency('').then((data: any) => {
        if (data.status === 1) {
          this.billingData = data.data.billingData;
        }
      },
      (err: HttpErrorResponse) => {

      });
    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
        }

      },
      (err: HttpErrorResponse) => { });
    this.userService.viewBranch('').then((data: any) => {
        if (data.status === 1) {
          this.branchData = data.data.branchData;
        }
    
    //
      },
      (err: HttpErrorResponse) => {
    
      });
    
    this.userService.viewState('').then((data: any) => {
        if (data.status === 1) {
          this.stateData = data.data.stateData;
        }
    
    
      },
      (err: HttpErrorResponse) => {
    
      });
    this.userService.viewMapping('').then((data: any) => {
        if (data.status === 1) {
          this.mapData = data.data.mapData;
        }
      },
      (err: HttpErrorResponse) => {

      });

    if (this.usertype==2)
    {
      this.userService.viewClient_branch(this.branch).then((data: any) => {
          if (data.status === 1) {
            this.clientData = data.data.clientData;
            console.log("Select Client");
            console.log(this.clientData);
          }
        },
        (err: HttpErrorResponse) => {

        });
    }

    this.userService.viewOperation('').then((data: any) => {
      console.log("=========== Data Status ===========");
      console.log(data)
      console.log(data.status);
        if (data.status === 1) {
          this.operationData = data.data.operationData;
          console.log("=========== Select Operation ===========");
          console.log(this.operationData);
        }
      },
      (err: HttpErrorResponse) => {

      });
     this.tablevisible=1;


    if (this.usertype == 1) {
      this.userService.viewCalls(this.token).then((data: any) => {
          this.tablevisible=1;
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2) {
      this.userService.viewCallByBranch(this.branch).then((data: any) => {
          this.tablevisible=1;
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.dtTrigger.next();
            this.loader=false;
            }
        },
        (err: HttpErrorResponse) => {

        });
    }

     this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    
      
  }


  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();

    return this.clientData.filter(option => option.clientcode.toLowerCase().includes(filterValue));
  }
    regionSelect(event) {
      this.userService.viewStateByRegion(event.value).then((data: any) => {
          if (data.status === 1) {
            this.stateData = data.data.stateData;
            this.dtTrigger.next();
          }
        },
        (err: HttpErrorResponse) => {
  
        });
    }
  
  
    stateSelect(event) {
      this.userService.viewBranchByState(event.value).then((data: any) => {
          if (data.status === 1) {
            this.branchData = data.data.branchData;
            this.dtTrigger.next();
          }
        },
        (err: HttpErrorResponse) => {
  
        });
    }
    BranchSelect(event) {
  
      if (this.usertype==1)
      {
        this.userService.viewClient_branch(event.value).then((data: any) => {
            if (data.status === 1) {
              this.clientData = data.data.clientData;
            }
          },
          (err: HttpErrorResponse) => {
  
          });
      }
  
    }
  
  
  
    getServicesList()
    {
    if(this.usertype == 1) {
      this.userService.viewCalls('').then((data: any) => {
          if (data.status === 1) {
            this.serviceData = data.data.regionData;
            this.rerender();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {
  
        });
    }
    else if(this.usertype == 2) {
      this.userService.viewCallByBranch(this.branch).then((data: any) => {
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.rerender();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {
  
        });
    }
    }



    funDelete(service_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
  
        this.userService.deleteSerivce(service_id).then((data : any)=>{
  
            this.status = data.status;
            if (data.status === 1) {
              this.getServicesList();
            }
  
          },
          (err : HttpErrorResponse) => {
          });
      });
    }


  newdelete = false;
  onDeleteChange(i,data,event) {
    this.delele_sch = false ;
    this.delete_record = data ;
    let index = this.deleteid.indexOf(this.delete_record.service_id);
    if (index === -1) {
        this.deleteid.push(this.delete_record.service_id);
        this.delete_record.selected = true;
    } else {
        this.deleteid.splice(index,1);
        this.delete_record.selected = false;
    }

  }
  DeleteMultipleRecord(){
    for(let i=0;i<this.deleteid.length;i++){
      this.userService.deleteSerivce(this.deleteid[i]).then((data : any)=>{
        this.status = data.status;
        location.reload();
        this.delele_sch = true ;
        if (data.status === 1) {
          this.getServicesList();
        }

      });


    }
  }



    funEdit(index) {
      window.scrollTo(0, 0);
      const editData = this.serviceData[index];
      console.log(editData);
      this.selected  = editData.region_id;
      this.userService.viewStateByRegion(this.selected).then((data: any) => {
          if (data.status === 1) {
            this.stateData = data.data.stateData;
  
          }
        },
        (err: HttpErrorResponse) => {
  
        });
      this.selectedstate = editData.state_id;
      this.userService.viewBranchByState(this.selectedstate).then((data: any) => {
          if (data.status === 1) {
            this.branchData = data.data.branchData;
  
          }
        },
        (err: HttpErrorResponse) => {
  
        });
      
      this.selectedbranch = editData.branch_id;
      this.selectedclient = editData.clientcode;
      this.sitename = editData.sitename;
      this.selectedoperation = editData.operation_id;
      this.servicedate = editData.dateoftreatment;
      this.servicedate = new Date(this.servicedate);
      this.technician = editData.technicianname;
      this.mobile = editData.mobilenumber;
      this.selectedfrequency = editData.billing_id;
      this.selectedstatus = editData.service_status;
      this.ponumber = editData.purchaseorder_no;
      this.value = editData.amount;
      this.service_id = editData.service_id;
      this.action = 'edit';
      this.clienttype = 1;
      console.log(this.selectedoperation);
      console.log(this.servicedate);
    }
    onChange(event)
    {
      
      this.branch = event.value;
    }
  
    searchrecord()
    {
      this.localTime  = moment.utc(this.loginForm1.value.fromdate).toDate();
      this.date = moment(this.localTime).format('DD-MM-YYYY');
  
      this.localTime1  = moment.utc(this.loginForm1.value.todate).toDate();
      this.date1 = moment(this.localTime1).format('DD-MM-YYYY');
   
      if ((this.loginForm1.value.fromdate!=undefined) && (this.loginForm1.value.todate!=undefined))
      {
      this.userService.viewCalls_date(this.branch,this.date,this.date1).then((data: any) => {
        if (data.status === 1) {
       this.tablevisible=1;
          this.serviceData = data.data.serviceData;
    this.rerender()
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {
  
      });
    }
    }
  



  
    onSubmit() {
      this.localTime  = moment.utc(this.loginForm.value.servicedate).toDate();
      this.date = moment(this.localTime).format('DD-MM-YYYY');
  console.log(this.date);
        if (this.usertype == 1)
        {
          if(this.action === 'save'){
            this.userService.addService(this.loginForm.value.clienttype, this.loginForm.value.region, this.loginForm.value.branch, this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.loginForm.value.state, this.loginForm.value.value, this.loginForm.value.ponumber ).then((data : any)=>{
                this.status= data.status;
                if (data.status === 1)
  
                {
                  Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.getServicesList();
                  this.loginForm.reset();
                }
  
  
              },
              (err: HttpErrorResponse) => {
              });
          }
        }
  
        if (this.usertype == 2)
        {
          if(this.action === 'save'){
            this.userService.addService_user(this.loginForm.value.clienttype, this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.loginForm.value.value, this.loginForm.value.ponumber ).then((data : any)=>{
                this.status= data.status;
                if (data.status === 1)
  
                {
                  Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.getServicesList();
                  this.loginForm.reset();
                }
  
  
              },
              (err: HttpErrorResponse) => {
              });
          }
        }
  
  
  
  
          if(this.action === 'edit'){
  
            if (this.usertype == 1) {
              this.userService.editService_1(this.loginForm.value.clienttype, this.loginForm.value.region, this.loginForm.value.branch, this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.loginForm.value.state, this.service_id, this.loginForm.value.value, this.loginForm.value.ponumber).then((data: any) => {
                  this.status = data.status;
                  if (data.status === 1) {
                    Swal.fire({
                      position: 'top-end',
                      type: 'success',
                      title: 'Updated successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                    this.getServicesList();
                    this.router.navigate(['/createcall']);
                  }
                  else if (data.status === 0) {
                    Swal.fire({
                      position: 'top-end',
                      type: 'warning',
                      title: 'Already Exists.',
                      showConfirmButton: false,
                      timer: 1500
                    })
  
                    this.getServicesList();
                    this.loginForm.reset();
                  }
                },
                (err: HttpErrorResponse) => {
                });
  
            }
  
            if (this.usertype == 2)
            {
              this.userService.editService('2', this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.service_id, this.loginForm.value.value, this.loginForm.value.ponumber).then((data: any) => {
                  this.status = data.status;
                  if (data.status === 1) {
                    Swal.fire({
                      position: 'top-end',
                      type: 'success',
                      title: 'Updated successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                    this.getServicesList();
                   this.router.navigate(['/viewservice']);
                  }
                  else if (data.status === 0) {
                    Swal.fire({
                      position: 'top-end',
                      type: 'warning',
                      title: 'Already Exists.',
                      showConfirmButton: false,
                      timer: 1500
                    })
  
                    this.getServicesList();
                    this.router.navigate(['/createcall']);
                  }
                },
                (err: HttpErrorResponse) => {
                });
            }
          }
  
      }


ngOnDestroy(): void {
  this.dtTrigger.unsubscribe();
}
rerender(): void {
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.destroy();
    this.dtTrigger.next();
  });
}
clearfilterOption(){
    this.datatable();
}
datatable(){
  this.loader=true;
 this.tablevisible=1;
if (this.usertype == 1) {
  this.userService.viewCalls(this.token).then((data: any) => {
      this.tablevisible=1;
      if (data.status === 1) {
        this.serviceData = data.data.serviceData;
        this.rerender();
        this.loader=false;
      }
    },
    (err: HttpErrorResponse) => {

    });
}
else if(this.usertype == 2) {
  this.userService.viewCallByBranch(this.branch).then((data: any) => {
      this.tablevisible=1;
      if (data.status === 1) {
        this.serviceData = data.data.serviceData;
        this.rerender();
        this.loader=false;
        }
    },
    (err: HttpErrorResponse) => {
    });
}
}



}


