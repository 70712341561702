

export const environment = {
  production: false,
  ApiUrl : 'http://app.hvlpestservices.com/backend/index.php',
  imgUrl: 'http://app.hvlpestservices.com/backend/uploads'

  // ApiUrl : 'https://testinglink.in/hvl/backend/index.php',
  // imgUrl: 'https://testinglink.in/hvl/backend/uploads'

  // ApiUrl : 'http://testinglink.in/hvl-backend/index.php'
//  ApiUrl : 'https://testinglink.in/hvl/backend/index.php',
};

