import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../user.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';

import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';



@Component({
  selector: 'app-viewcompleted',
  templateUrl: './viewcompleted.component.html',
  styleUrls: ['./viewcompleted.component.scss']
})
export class ViewcompletedComponent implements OnInit, OnDestroy,AfterViewInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  branch: any;
  serviceData: any;
  usertype: any;
  token: any;
  dataTable: any;
  loader:any;
  loginForm: FormGroup;
  localTime:any;
  date:any;
  localTime1:any;
  date1:any;
  tablevisible:any;
  branchData:any;
  service_id:any;
  status:any;
  constructor(private userService: UserService, private chRef: ChangeDetectorRef, private formBuilder: FormBuilder) { }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }
    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
    this.loginForm = this.formBuilder.group({
      fromdate: ['' ],
      todate: [''],

    });
    this.tablevisible=1;
    this.loader=true;

    this.userService.viewBranch1('').then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.branchData = data.data.branchData;
      }
    },
    (err: HttpErrorResponse) => {
    });
if(this.usertype == 1) {
if (this.branch!="null")
{
  this.userService.viewUserByBranchCompleted(this.branch).then((data: any) => {
    console.log(data);
    if (data.status === 1) {
      this.serviceData = data.data.serviceData;
      this.rerender()
      this.loader=false;
    }
  },
  (err: HttpErrorResponse) => {
  });
}
else{
  
  this.userService.viewCallsByCompleted('').then((data: any) => {
    console.log(data);
    if (data.status === 1) {
      this.serviceData = data.data.serviceData;
      this.loader=false;
      this.rerender()

    }
  },(err: HttpErrorResponse) => {});
}
}
    else if(this.usertype == 2) {
      this.userService.viewUserByBranchCompleted(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.rerender()
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {
        });
    }

    else if(this.usertype == 3) {
      this.userService.viewUserByClientCompleted(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.rerender()
            this.loader=false;
          }},(err: HttpErrorResponse) => {});
    }
  }
  fununcomplete(index) {
    const editData = this.serviceData[index];
    this.service_id = editData.service_id;
    alert(this.service_id);
    swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm!'
    }).then((result) => {

if (result.value==true)
{
      this.userService.viewServicesDashboard1(this.service_id).then((data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
       //     this.getServicesList();
            location.reload();
          }
        },
        (err : HttpErrorResponse) => {
        });
}
    })
  }
 
  onChange(event)
  {
    this.branch = event.value;
  }
  searchrecord()
  {
    this.localTime  = moment.utc(this.loginForm.value.fromdate).toDate();
    this.date = moment(this.localTime).format('DD-MM-YYYY');

    this.localTime1  = moment.utc(this.loginForm.value.todate).toDate();
    this.date1 = moment(this.localTime1).format('DD-MM-YYYY');
    console.log(this.date);
    console.log(this.date1);
    if ((this.loginForm.value.fromdate!=undefined) && (this.loginForm.value.todate!=undefined))
    {
    this.userService.viewCompletedCalls_date(this.branch,this.date,this.date1).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
     this.tablevisible=1;
        this.serviceData = data.data.serviceData;
        console.log(this.serviceData);
        this.rerender();
        this.loader=false;
      }
    },
    (err: HttpErrorResponse) => {

    });
  }
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  exporttoexcel()
  {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'schedule_completed.xls', { bookType: 'xls', type: 'buffer' });
  }



  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  clearfilterOption(){
    this.datatable();
    }
    datatable(){
      this.tablevisible=1;
      this.loader=true;
  
      this.userService.viewBranch1('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchData = data.data.branchData;
        }
      },
      (err: HttpErrorResponse) => {
      });
  if(this.usertype == 1) {
  if (this.branch!="null")
  {
    this.userService.viewUserByBranchCompleted(this.branch).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.serviceData = data.data.serviceData;
        this.rerender()
        this.loader=false;
      }
    },
    (err: HttpErrorResponse) => {
    });
  }
  else{
    
    this.userService.viewCallsByCompleted('').then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.serviceData = data.data.serviceData;
        this.loader=false;
        this.rerender()
  
      }
    },(err: HttpErrorResponse) => {});
  }
  }
      else if(this.usertype == 2) {
        this.userService.viewUserByBranchCompleted(this.branch).then((data: any) => {
            console.log(data);
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              this.rerender()
              this.loader=false;
            }
          },
          (err: HttpErrorResponse) => {
          });
      }
  
      else if(this.usertype == 3) {
        this.userService.viewUserByClientCompleted(this.branch).then((data: any) => {
            console.log(data);
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              this.rerender()
              this.loader=false;
            }},(err: HttpErrorResponse) => {});
      }
  
    }







}
