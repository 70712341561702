import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef,Inject } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { UserService } from '../user.service';
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import * as moment from 'moment';

export interface DialogData {
  scheduleinfo: string;
  name: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  treatmentdate: any;
  treatment_id: any;


  serviceData: any;
  status: any;
  service_id: any;
  branch: any;
  retrievedData: any;
  displayservice: any;
  completedCount: any;
  pendingCount: any;
  usertype: any;
  token: any;
  dataTable: any;
  branchData:any;
  loader:any;
  localTime:any;
  date:any;
  localTime1:any;
  date1:any;
  tablevisible:any;
  scheduledetail: any;
  scheduleinfo: string;
  userfname: string;
  userlname: string;



  constructor(private userService: UserService, private formBuilder: FormBuilder,
     private httpClient: HttpClient,  private chRef: ChangeDetectorRef,
     public dialog: MatDialog,
     ) { 
    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.userfname = localStorage.getItem('userFname');
    this.userlname = localStorage.getItem('userLname');
    console.log(this.branch);

  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }


    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
    this.tablevisible=1;
    this.loader=true;
    if(this.usertype == 1) {
      this.userService.viewCallsByPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.dtTrigger.next();
            // this.rerender();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallsByBranchPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            // this.rerender();
            this.dtTrigger.next();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }

    if(this.usertype == 1) {
      this.userService.viewGetCount('').then((data: any) => {
          console.log(data);
          this.completedCount = data.data.completedCount;
          console.log(this.completedCount);
          this.pendingCount = data.data.pendingCount;
          console.log(this.pendingCount);

        },
        (err: HttpErrorResponse) => {

        });
    }

    if(this.usertype == 2) {
      this.userService.viewGetCount_user(this.branch).then((data: any) => {
          console.log(data);
          this.completedCount = data.data.completedCount;
          console.log(this.completedCount);
          this.pendingCount = data.data.pendingCount;
          console.log(this.pendingCount);

        },
        (err: HttpErrorResponse) => {

        });
    }

    

  }



// {
//   this.dtTrigger.next();
// }


  exporttoexcel()
  {

 //   alert(this.fileName);
    console.log(this.serviceData);

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'client_list.xls', { bookType: 'xls', type: 'buffer' });


    // /* generate worksheet */
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.clientData);
    //
    // /* generate workbook and add the worksheet */
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    // /* save to file */
    // XLSX.writeFile(wb, this.fileName);
  }





  // funEdit(index) {
  //   const editData = this.serviceData[index];
  //   this.service_id = editData.service_id;
  //   swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, confirm!'
  //   }).then((result) => {

  //     this.userService.viewServicesDashboard(this.service_id,'').then((data : any)=>{

  //         this.status = data.status;
  //         if (data.status === 1) {
  //      //     this.getServicesList();
  //           location.reload();
  //         }

  //       },
  //       (err : HttpErrorResponse) => {
  //       });
  //   })
  // }




  openDialog(treatment_id,treatment): void {
    console.log(treatment_id);
    console.log(treatment);
    this.treatmentdate = treatment ;
    this.treatment_id = treatment_id ;
    
    const dialogRef = this.dialog.open(dashboardsetcompleteDialog, {
      width: '30%',
      data: {treatment_id:this.treatment_id,treatmentdate: this.treatmentdate}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
      this.datatable();
    });
  }


  openDialogInfo(data){
console.log(data)
this.scheduledetail = data ;
const dialogRef = this.dialog.open(dashboardschudleinfo, {
  width: '50%',
  data: {fulldetail:this.scheduledetail}
});

dialogRef.afterClosed().subscribe(result => {
  console.log('The dialog was closed');
  this.scheduleinfo = result;
});
}




  getServicesList(){
    
    if(this.usertype == 1) {
      this.userService.viewCallsByPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
             this.loader =false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallByBranch(this.token).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.loader =false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
  }









  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

rerender(): void {
this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  dtInstance.destroy();
  this.dtTrigger.next();
});
}



  datatable(){

    this.tablevisible=1;
    this.loader=true;
    if(this.usertype == 1) {
      this.userService.viewCallsByPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.rerender();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallsByBranchPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.rerender();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }

  }










}














@Component({
  selector: 'setcompletepopup',
  templateUrl: 'setcompletepopupdashboard.html',
})
export class dashboardsetcompleteDialog {
  selectdate = new Date();
  selecteddate: any;
  selecteddate_current: string;
  constructor(
    public dialogRef: MatDialogRef<dashboardsetcompleteDialog>,
    private userService: UserService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


  SetoperationDate(data){
    this.dialogRef.close();
    console.log(data)
    console.log(this.selecteddate);


    this.selecteddate_current = moment(this.selecteddate).format('YYYY-MM-DD');
    console.log(this.selecteddate_current)
    this.userService.viewServicesDashboard(data,this.selecteddate_current).then((data : any)=>{

      if (data.status === 1) {
   //     this.getServicesList();
        // location.reload();
      }
  
    });
  }
}







@Component({
  selector: 'setcomplete-popup',
  templateUrl: 'setcompletepopupinfo.html',
})
export class dashboardschudleinfo {
  // selectdate = new Date();
  // selecteddate: any;
  // selecteddate_current: string;


  constructor(
    public dialogRef: MatDialogRef<dashboardschudleinfo>,
    private userService: UserService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


  // SetoperationDate(data){
  //   this.dialogRef.close();
  //   console.log(data)
  
  //   this.selecteddate_current = moment(this.selecteddate).format('YYYY-MM-DD');
  //   console.log(this.selecteddate_current)
  //   this.userService.viewServicesDashboard(data,this.selecteddate_current).then((data : any)=>{

  //     if (data.status === 1) {
  //  //     this.getServicesList();
  //       location.reload();
  //     }
  
  //   });
  // }
}

