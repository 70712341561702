import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { UserService} from '../user.service';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  loginForm: FormGroup;
  regionData: any;
  stateData: any;
  branchData: any;
  action: any;
  status:any;
  mapData:any;
  state:any;
  stateId:any;
  region:any;
  selected:any;
  isLoginError:any;
  dataTable: any;
  delete_record:any;
  deleteid=[];
  public delele_sch = true;
  constructor(private userService: UserService, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) { }

  ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }

    this.loginForm = this.formBuilder.group({
      region   : ['', [Validators.required]],
      state   : ['', Validators.compose([Validators.required, Validators.pattern('[a-z A-Z]*'),  Validators.minLength(2), Validators.maxLength(30)])],

    });

    this.action = 'save';
    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
          //this.cacheForecasts = this.regionData;
          console.log(this.regionData);
        }


      },
      (err: HttpErrorResponse) => {

      });


    this.userService.viewState('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.stateData = data.data.stateData;
          this.dtTrigger.next();

        }


      },
      (err: HttpErrorResponse) => {

      });


  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  funEdit(index) {
    const editData = this.stateData[index];
    this.selected  = editData.region_id;
    this.stateId = editData.state_id;
    this.state = editData.state_name;
    this.action = 'edit';
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }
  getStateList()
  {


    this.userService.viewState('').then((data: any) => {
        if (data.status === 1) {
          this.stateData = data.data.stateData;

        }

      },
      (err: HttpErrorResponse) => {
      });
  }
  async funDelete(state_id)
  {
    await swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {


        if (result.value) {
          this.userService.deleteState(state_id).then(async (data : any)=>{

            this.status = data.status;
            if (data.status === 1) {
              this.getStateList();
              await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
            }
  
          },
          (err : HttpErrorResponse) => {
          });
        } else {
          await  swal.fire("Cancelled", "Item cannot be deleted :)", "error");
        }
    });

  }
  onSubmit() {
    console.log(this.loginForm);
    if (this.loginForm.valid) {
      console.log(this.loginForm.value.region);
      if(this.action === 'save'){
        this.userService.addState(this.loginForm.value.region, this.loginForm.value.state).then((data : any)=>{
            console.log(data);
            this.status= data.status;
            if (data.status === 1)

            {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getStateList();
              this.loginForm.reset();
              this.rerender();
            }


          },
          (err: HttpErrorResponse) => {
            console.log('error');
          });
      }
        if(this.action === 'edit') {

          this.userService.editState( this.loginForm.value.state, this.stateId, this.loginForm.value.region ).then((data : any)=>{
              console.log(data);



              this.status = data.status;
              if (data.status === 1) {

                swal.fire({
                  position: 'top-end',
                  type: 'success',
                  title: 'Saved successfully.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getStateList();
              }
              else if (data.status === 0)

              {
                swal.fire({
                  position: 'top-end',
                  type: 'warning',
                  title: 'Already Exists.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getStateList();
                // this.reset();
              }


            },
            (err : HttpErrorResponse) => {
            this.isLoginError = true;
            });
            this.rerender();
        }

        console.log(this.loginForm.value.region);
      }

    }


    newdelete = false;
    onDeleteChange(i,data,event) {
      this.delele_sch = false ;
      this.delete_record = data ;
      let index = this.deleteid.indexOf(this.delete_record.state_id);
      if (index === -1) {
          this.deleteid.push(this.delete_record.state_id);
          this.delete_record.selected = true;
      } else {
          this.deleteid.splice(index,1);
          this.delete_record.selected = false;
      }
      console.log(this.deleteid);
    }
    async DeleteMultipleRecord(){
    
      await swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
      if (result.value) {
            
      for(let i=0;i<this.deleteid.length;i++){
        this.userService.deleteState(this.deleteid[i]).then(async (data : any)=>{
          this.status = data.status;
          if (data.status === 1) {
            this.getStateList();
            await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
          }
        });

      }
          } else {
            await  swal.fire("Cancelled", "Item cannot be deleted :)", "error");
          }
      });

    }




}
