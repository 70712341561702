import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { UserService } from '../user.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { map, filter } from 'rxjs/operators';




@Component({
  selector: 'app-uploadschedule',
  templateUrl: './uploadschedule.component.html',
  styleUrls: ['./uploadschedule.component.scss']
})
export class UploadscheduleComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  btndisable:any;
  CallsData: any;
  loginForm: FormGroup;
  regionData: any;
  stateData: any;
  branchData: any;
  action: any;
  status: any;
  serviceData: any;
  branch: any;
  selected: any;
  selectedstate: any;
  selectedbranch: any;
  selectedclient: any;
  dataTable: any;
  token: any;
  usertype: any;
  ufilename: any;
  logData: any;
  tablevisible: number;
  loader: any;
 

  constructor(public userService: UserService, private httpClient: HttpClient, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) {
    this.branch = localStorage.getItem('userBranch');
    this.token = localStorage.getItem('userToken');
    this.usertype = localStorage.getItem('userType');

  }
  viewcallList()
  {
    this.userService.viewCallsByUpload( this.branch).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.CallsData = data.data.serviceData;
          this.loader=false;
          this.rerender();

        }
      },
      (err: HttpErrorResponse) => {
  
      });
  }


  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,
      processing: true
    }
    this.tablevisible=1;
    this.loader=true;
    this.userService.viewUploadLog( this.branch).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.CallsData = data.data.logData;
        this.dtTrigger.next();
        this.loader=false;
      }
    },
    (err: HttpErrorResponse) => {

    });

    this.loginForm = this.formBuilder.group({
      region   : ['', [Validators.required]],
      state   : ['', [Validators.required]],
      branch   : ['', [Validators.required]]

    });
    this.action = 'save';
    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
          console.log(this.regionData);
          this.rerender();
          this.loader=false;
        }


      },
      (err: HttpErrorResponse) => {

      });

    this.userService.viewCalls('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.serviceData = data.data.regionData;
          this.rerender();
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {

      });
  }

onSubmit() {
  this.btndisable=true;
      if (this.usertype == 1) {
      if (this.loginForm.valid) {
        this.uploadFileToActivity(this.loginForm.value.region, this.loginForm.value.state, this.loginForm.value.branch);
      }
    }
    if (this.usertype == 2) {

      this.uploadFileToActivity('', '', '');
    }
    // this.fileToUpload = null;

}





fileToUpload: File = null;

handleFileInput(files: FileList) {
 // alert("test1");
  this.fileToUpload = files.item(0);

}

postFile(fileToUpload: File, region, state,branch): Observable<any> {
  console.log(fileToUpload.name);
this.ufilename = fileToUpload.name
//    const endpoint = 'http://testinglink.in/demo/index.php/csv_import/import';
  if (this.usertype == 1) {
  //  const endpoint = 'http://testinglink.in/demo/index.php/csv_import/import';
   // const endpoint = 'https://testinglink.in/hvlbackend-new/index.php/csv_import/import_user';
     const endpoint = ' http://app.hvlpestservices.com/backend/index.php/csv_import/import_user';
   
    const formData: FormData = new FormData();
    formData.append('csv_file', fileToUpload, fileToUpload.name);
    formData.append('region', region);
    formData.append('state', state);
    formData.append('branch', branch);
    formData.append('token', this.token);
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'No-Auth':'True'
    });
    return this.httpClient.post(endpoint, formData, {headers: headers})
    .pipe(map(data => {
     // alert(data);
      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Saved successfully.',
        showConfirmButton: false,
        timer: 1500
      })
      return data;

    }));
  }
  if (this.usertype == 2)
  {

   // const endpoint = 'http://testinglink.in/demo/index.php/csv_import/import_user';
    //const endpoint = 'https://testinglink.in/hvlbackend-new/index.php/csv_import/import_user';
     const endpoint = 'http://app.hvlpestservices.com/backend/index.php/csv_import/import_user';
    
    const formData: FormData = new FormData();
    formData.append('csv_file', fileToUpload, fileToUpload.name);
    formData.append('token', this.token);
    formData.append('usrbranch', this.branch);
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'No-Auth':'True'
    });
    return this.httpClient.post(endpoint, formData, {headers: headers})
      .pipe(map(data => {
      //  alert(data);
        swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Saved successfully.',
          showConfirmButton: false,
          timer: 1500
        })
        return data;

      }));
  }


}


uploadFileToActivity(region, state, branch) {
  this.postFile(this.fileToUpload, region, state, branch).subscribe(data => {
    console.log(data);
    this.status = data.status;
    if (this.status === 1) {
      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Saved successfully.',
        showConfirmButton: false,
        timer: 1500
      })
      // this.getBranchList();
      this.loginForm.reset();
    }
    if (this.status === 1) {
      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Partially Uploaded.',
        showConfirmButton: false,
        timer: 1500
      })
      // this.getBranchList();
      this.loginForm.reset();
    }
    this.viewcallList();
    this.loginForm.reset();
  }, error => {
    console.log(error);
  });
}













  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  clearfilterOption(){
    this.datatable();
    }
    datatable(){
      this.tablevisible=1;
      this.loader=true;
      this.userService.viewUploadLog( this.branch).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.CallsData = data.data.logData;
          this.rerender();
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {
  
      });
    }
}
