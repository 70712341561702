import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  token: any;
  constructor(public http:HttpClient, private router: Router) { }

//   getToken() {
//     this.token = localStorage.getItem('userToken');

//     if (this.token === '') {
//       this.router.navigate(['/login']);
//     }
//   }
// // Login user service all here
// LoginApi(userName, password) {
//   this.getToken();
//   // return this.http.post(`${environment.ApiUrl}/login`,{userName:userName,password:password});
//   const headers = new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//     'No-Auth': 'True'
//   });

//   const params = new HttpParams().set('username', userName).set('password', password).toString();

//   interface PostReturn {
//     status: string;
//     data: any;
//     message: string;
//     loginstatus: any;
//   }

//   return new Promise((resolve, reject) => {
//     this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/login',
//       params, {headers: headers}).subscribe(
//       data => {
//         // alert("in");
//         resolve(data);


//       }, (err: HttpErrorResponse) => {
//         console.log(err.error);
//         console.log(err.name);
//         console.log(err.message);
//         console.log(err.status);
//       });
//   });
// }







// Get Login token and verify before go in to more
getToken() {
  this.token = localStorage.getItem('userToken');

  if (this.token === '') {
    this.router.navigate(['/login']);
  }
}
// Register new user service all here
// registerUser(user: User) {
//   this.getToken();
//   const body: User = {
//     UserName: user.UserName,
//     Password: user.Password,
//     Email: user.Email,
//     FirstName: user.FirstName,
//     LastName: user.LastName
//   };
//   const reqHeader = new HttpHeaders({'No-Auth': 'True'});
//   return this.http.post(`${environment.ApiUrl}`+ '/api/User/Register', body,{headers : reqHeader});
// }
// Login user service all here
loginApi(userName, password) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('username', userName).set('password', password).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/login',
      params, {headers: headers}).subscribe(
      data => {
        // alert("in");
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
} 


addRegion(region) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('region', region).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/region/addregion',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

deleteRegion(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('region_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/region/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}
editRegion(region, id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('status', '1').set('region_id', id).set('region', region).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/region/updateregion',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

viewRegion(region) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/region/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

addState(region, state) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('region_id', region).set('state_name', state).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/state/addstate',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

deleteState(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('state_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/state/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

editState(state, id, region) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('status', '1').set('state_id', id).set('state_name', state).set('region_id', region).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/state/updatestate',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

viewStateByRegion(regionid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).set('region_id', regionid).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/state/viewbyregion',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewState(state) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/state/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });

}

viewCompletedCalls_date(branch,fromdate,todate) {
  
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).set('fromdate', fromdate).set('todate', todate).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbydate_complete',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}



viewCalls_date(branch,fromdate,todate) {
  
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).set('fromdate', fromdate).set('todate', todate).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbydate',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewCalls(branch) {
  // alert(branch);
   this.getToken();
   const headers = new HttpHeaders({
     'Content-Type':  'application/x-www-form-urlencoded',
     'Access-Control-Allow-Origin': '*',
     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
     'No-Auth': 'True'
   });

   const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

   interface PostReturn {
     status: string;
     data: any;
     message: string;
     loginstatus: any;
   }

   return new Promise((resolve, reject) => {
     this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/view',
       params, {headers: headers}).subscribe(
       data => {
         console.log(data);
         resolve(data);


       }, (err: HttpErrorResponse) => {
         console.log(err.error);
         console.log(err.name);
         console.log(err.message);
         console.log(err.status);
       });
   });
 }



 viewUploadClientLog(id) {

  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewclientlog',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewUploadLog(id) {

  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewlog',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}



viewCallsByUpload(id) {
  //alert(id);
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewCallsByBranchPending(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewCallsByPending(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewCallsByClientPending(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbyclient',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
viewUserByClientCompleted(branch) {
  // alert(branch);
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbyclient_completed',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewCalls_date_client(branch,fromdate,todate) {
    
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).set('fromdate', fromdate).set('todate', todate).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbydate_client',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}



viewCallsByDisplaying(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('service_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/singleview',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
/*branch service*/

addBranch(region, state, branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('region_id', region).set('state_id', state).set('branch_name', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/addbranch',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

deleteBranch(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}
editBranch( branch, id, state, region) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('status', '1').set('branch_id', id).set('branch_name', branch).set('state_id', state).set('region_id', region).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/updatebranch',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

viewBranchByState(stateid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).set('state_id', stateid).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/viewbystate',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

// getBranch(stateid) {
//   this.getToken();
//   const headers = new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//     'No-Auth': 'True'
//   });

//   const params = new HttpParams().set('token', this.token).set('state_id', stateid).toString();

//   interface PostReturn {
//     status: string;
//     data: any;
//     message: string;
//     loginstatus: any;
//   }

//   return new Promise((resolve, reject) => {
//     this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/viewbystate',
//       params, {headers: headers}).subscribe(
//       data => {
//         console.log(data);
//         resolve(data);


//       }, (err: HttpErrorResponse) => {
//         console.log(err.error);
//         console.log(err.name);
//         console.log(err.message);
//         console.log(err.status);
//       });
//   });
// }

viewCategory(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client_category/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewBranch1(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewBranch(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/branch/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


addOperation(operation) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('operation_name', operation).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/operation/addoperation',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

deleteOperation(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('operation_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/operation/delete',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

editOperation(operation, id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('status', '1').set('operation_id', id).set('operation_name', operation).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/operation/updateoperation',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

// viewOperation(operation) {
//   this.getToken();
//   const headers = new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//     'No-Auth': 'True'
//   });

//   const params = new HttpParams().set('token', this.token).toString();

//   interface PostReturn {
//     status: string;
//     data: any;
//     message: string;
//     loginstatus: any;
//   }

// //   return new Promise((resolve, reject) => {
// //     this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/operation/view',
// //       params, {headers: headers}).subscribe(
// //       data => {
// //         console.log(data);
// //         resolve(data);


// //       }, (err: HttpErrorResponse) => {
// //         console.log(err.error);
// //         console.log(err.name);
// //         console.log(err.message);
// //         console.log(err.status);
// //       });
// //   });
// // }



viewOperation(operation) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+'/operation/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}








searchMapping(region, state, branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('status', '1').set('reg_id', region).set('st_id', state).set('br_id', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/mapping/addmapping',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}


viewMapping(maping) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/mapping/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewClient(client) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
viewClient_branch(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client/viewbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}




addService(clienttype, region , branch, client, operation, frequency, servicedate, technician, mobile, servicestatus, sitename, state, value,ponumber) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('business', '2').set('region', region).set('branch', branch).set('clientname', client).set('operation', operation).set("frequency", frequency).set("dateoftreatment", servicedate).set("technicianname", technician).set("mobilenumber", mobile).set("service", servicestatus).set("sitename", sitename).set("state", state).set("amount",value).set("pono",ponumber).set("token", this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/addnewservice',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

addService_user(clienttype, client, operation, frequency, servicedate, technician, mobile, servicestatus, sitename, value, ponumber) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('business', '2').set('clientname', client).set('operation', operation).set('frequency', frequency).set('dateoftreatment', servicedate).set('technicianname', technician).set('mobilenumber', mobile).set('service', servicestatus).set('sitename', sitename).set('amount', value).set('pono', ponumber).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/addnewservice_user',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


editService_1(clienttype, region , branch, client, operation, frequency, servicedate, technician, mobile, servicestatus, sitename, state, service_id, value, ponumber) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });
  const params = new HttpParams().set('business', clienttype).set('region', region).set('branch', branch).set('clientname', client).set('operation', operation).set('frequency', frequency).set('dateoftreatment', servicedate).set('technicianname', technician).set('mobilenumber', mobile).set('service', servicestatus).set('sitename', sitename).set('state', state).set('service_id', service_id).set('amount', value).set('pono', ponumber).set('status','1').set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/updatenewservice',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

editService(clienttype, client, operation, frequency, servicedate, technician, mobile, servicestatus, sitename, service_id, value, ponumber) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });
  const params = new HttpParams().set('business', clienttype).set('clientname', client).set('operation', operation).set('frequency', frequency).set('dateoftreatment', servicedate).set('technicianname', technician).set('mobilenumber', mobile).set('service', servicestatus).set('sitename', sitename).set('service_id', service_id).set('amount', value).set('pono', ponumber).set('status','1').set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/updatenewservice_branch',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
deleteSerivce(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('service_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}
viewFrequency(frequency) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/billing/listview',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

addUsers(fname, lname, email, mobile, address, branch, usertype, password) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('fname', fname).set('lname', lname).set('email', email).set('mobile', mobile).set('address', address).set('branch', branch).set('usertype', usertype).set('password', password).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/add_new_user/addnewuser',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewUsers(user) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/add_new_user/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
editUsers(fname, lname, email, mobile, address, branch, usertype, user_id) {
  this.getToken();
  let headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  let params = new HttpParams().set("fname", fname).set("lname", lname).set("email", email).set("mobile", mobile).set("address", address).set("branch", branch).set("usertype", usertype).set("user_id", user_id).set("token", this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/add_new_user/updatenewuser',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

editUsersPassword(fname, lname, email, mobile, address, branch, usertype, user_id, password) {
  this.getToken();
  let headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  let params = new HttpParams().set("fname", fname).set("lname", lname).set("email", email).set("mobile", mobile).set("address", address).set("branch", branch).set("usertype", usertype).set("user_id", user_id).set("password", password).set("token", this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/add_new_user/updatenewuser',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
editStaff(firstname, lastname, email, mobile, empid, branch, role, staffid) {
  this.getToken();
  let headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  let params = new HttpParams().set("firstname", firstname).set("lastname", lastname).set("email", email).set("mobile", mobile).set("empid", empid).set("branch", branch).set("role", role).set("staffid", staffid).set("token", this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/updatestaffsmaster',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

editStaffPassword(firstname, lastname, email, mobile, empid, branch, role, staffid, password) {
  this.getToken();
  let headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  let params = new HttpParams().set("firstname", firstname).set("lastname", lastname).set("email", email).set("mobile", mobile).set("empid", empid).set("branch", branch).set("role", role).set("staffid", staffid).set("password", password).set("token", this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/updatestaffsmaster',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

deleteUser(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  const params = new HttpParams().set('user_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/add_new_user/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

deleteStaff(staffid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  const params = new HttpParams().set('staffid', staffid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/deletestaffsmaster',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}
viewServicesDashboard(id,service_date) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('service_id', id).set('service_date', service_date).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/setcomplete',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewServicesDashboard1(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('service_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/setuncomplete',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

// create client

viewClientList_user(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client/viewbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewClientList(user) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}



addClients(cname, contactname, email, address, mobile, branch, city, gstnumber, shippingaddress, pincode, cid, category) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('client_name', cname).set('contact_person', contactname).set('email', email).set('address', address).set('mobile', mobile).set('branch', branch).set('city', city).set('gstno', gstnumber).set('shippingaddress', shippingaddress).set('pincode', pincode).set('clientcode', cid).set('category', category).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client/addnewclient',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
editClients(cname, contactname, email, address, mobile, branch, city, client_id, gstnumber, shippingaddress, pincode, cid, category,mcid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  const params = new HttpParams().set('client_name', cname).set('contact_person', contactname).set('email', email).set('address', address).set('mobile', mobile).set('branch', branch).set('city', city).set('client_id' , client_id).set('gstno', gstnumber).set('shippingaddress', shippingaddress).set('pincode', pincode).set('clientcode', cid).set('category', category).set('status', '1').set('token', this.token).set('mcid', mcid).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+'/client/updateclient',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
deleteClient(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  const params = new HttpParams().set('client_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus:any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/client/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}


logout(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth':'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/logout',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}

//billing
viewBilling(billing) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/billing/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
addBilling(billing) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('billing', billing).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/billing/addbilling',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
deleteBilling(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('billing_id', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/billing/delete',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}
editBilling(billing, id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('status', '1').set('billing_id', id).set('billing', billing).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/billing/updatebilling',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);

      });
  });
}


viewGetCount_user(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/getcount_user',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


viewGetCount_client(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/getcount_client',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}





viewGetCount(id) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/getcount',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

viewCallsByCompleted(branch) {
   
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/view_completed',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
//branch user
viewCallByBranch(branch) {
  // alert(branch);
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
viewUserByBranchCompleted(branch) {
  // alert(branch);
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/service/viewbybranch_completed',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
Getschedule(branchid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).set('branch', branchid).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/view',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

Getschedule1(branchid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).set('branch', branchid).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/view1',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

// ViewSchedulelog(){
//   this.http.post(`${environment.ApiUrl}`+ '/viewschedulelog',{})
// }
ViewSchedulelog(id) {

  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  // const params = new HttpParams().set('branch', id).set('token', this.token).toString();
  const params = new HttpParams().set('branch', id).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/viewschedulelog',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

getUserClaims(){
  return  this.http.get(`${environment.ApiUrl}`+'/api/GetUserClaims');
}

addNewStaffs(branch, firstname, lastname, email, mobile, empid, role, password ) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });
  const params = new HttpParams().set('branch', branch).set('firstname', firstname).set('lastname', lastname).set('email', email).set('mobile', mobile).set('empid', empid).set('role', role).set('password', password).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/addnewstaffsmaster',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

getAdminStaffs() {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/fullliststaffsbyadmin',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}


addNewbranchStaffs(branch, firstname, lastname, email, mobile, empid, role, password ) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });
  const params = new HttpParams().set('branch', branch).set('firstname', firstname).set('lastname', lastname).set('email', email).set('mobile', mobile).set('empid', empid).set('role', role).set('password', password).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/addnewstaffsmaster',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

getBranchStaffs(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch',branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/fullliststaffsbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

getAssignedBranchStaffs(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch',branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/liststaffsbybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

// $branch = $this->input->post('branch');
//             $userid = $this->input->post('userid');
//             $clientid = $this->input->post('clientid');
//             $assignedto = $this->input->post('assignedto');
//             $schedule_date = $this->input->post('schedule_date');
//             $schedule_info = $this->input->post('schedule_info');
//             $schedule_short = $this->input->post('schedule_short');

addAdminAudit(branch, clientid, assignedto, schedule_date, schedule_info, schedule_short ) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });
  const params = new HttpParams().set('branch', branch).set('clientid', clientid).set('assignedto', assignedto).set('schedule_date', schedule_date).set('schedule_info', schedule_info).set('schedule_short', schedule_short).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/addauditschedule',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
addNewAdminAudit(branch,assignedto, schedule_date, schedule_info, schedule_short , clientname, clientaddress, clientmobile, clientemail ) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });
  const params = new HttpParams().set('branch', branch).set('assignedto', assignedto).set('schedule_date', schedule_date).set('schedule_info', schedule_info).set('schedule_short', schedule_short).set('clientname', clientname).set('clientaddress', clientaddress).set('clientmobile', clientmobile).set('clientemail', clientemail).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/addauditschedule',
      params, {headers: headers}).subscribe(
      data => {

        console.log(data);
        resolve(data);


      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

listScheduleAdmin() {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/listschedulebyadmin',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

listScheduleBranch(branch) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch', branch).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/listschedulebybranch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
deleteAuditSchedule(scheduleid){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('scheduleid', scheduleid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/deleteauditschedule',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
filterAuditSchedule(branch,fromdate,todate){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch',branch).set('fromdate', fromdate).set('todate',todate).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/viewauditbydate',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });

}
getAuditedReport(auditinfoid){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('auditinfoid',auditinfoid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/getAuditReportbyID',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
getAuditedClient(auditinfoid,scheduleid){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('auditinfoid',auditinfoid).set('scheduleid',scheduleid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/getAuditReportClientInfo',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

getEmailClient(scheduleid){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('scheduleid',scheduleid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/scheduleemail',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

updateAuditReport(branch,userid,clientid,assignedto,schedule_date,schedule_info,schedule_short,scheduleid){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch',branch).set('userid',userid)
  .set('clientid',clientid).set('assignedto',assignedto).set('schedule_date',schedule_date)
  .set('schedule_info',schedule_info).set('schedule_short',schedule_short)
  .set('scheduleid',scheduleid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/updateauditschedule',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

updateAuditReportnew(branch,userid,clientid,assignedto,schedule_date,schedule_info,schedule_short,
  clientname,clientaddress,clientmobile,clientemail,scheduleid){
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('branch',branch).set('userid',userid)
  .set('clientid',clientid).set('assignedto',assignedto).set('schedule_date',schedule_date)
  .set('schedule_info',schedule_info).set('schedule_short',schedule_short).set('clientname',clientname)
  .set('clientaddress',clientaddress).set('clientmobile',clientmobile).set('clientemail',clientemail)
  .set('scheduleid',scheduleid).set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/updateauditschedule',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

getBranchCount() {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('token', this.token).toString();

  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/schedule_Count_Branch',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}

sendEmail(email,scheduleid,auditinfoid) {
  this.getToken();
  const headers = new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'No-Auth': 'True'
  });

  const params = new HttpParams().set('email',email).set('scheduleid',scheduleid).set('auditinfoid',auditinfoid).set('token', this.token).toString();
  interface PostReturn {
    status: string;
    data: any;
    message: string;
    loginstatus: any;
  }

  return new Promise((resolve, reject) => {
    this.http.post<PostReturn>(`${environment.ApiUrl}`+ '/schedule/EmailReport',
      params, {headers: headers}).subscribe(
      data => {
        console.log(data);
        resolve(data);
      }, (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
      });
  });
}
}
