import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import { UserService} from "../user.service";
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  delete_record:any;
  deleteid=[];
  public delele_sch = true;
  loginForm: FormGroup;
  operationData: any;
  operationlist: any;
  isLoginError = false;
  status: any;
  action:any;
  operation:any;
  operationId:any;
  dataTable: any;
  loader: boolean;
  tablevisible: number;
  constructor(private userService: UserService,  private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) { }
  ngOnInit() {
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }
    this.loginForm = this.formBuilder.group({
      operation   : ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*'),  Validators.minLength(2), Validators.maxLength(30)])],

    });

    this.tablevisible=1;
    this.loader=true;
    this.userService.viewOperation('').then((data: any) => {
        if (data.status === 1) {
          this.operationData = data.data.operationData;
          this.dtTrigger.next();
          this.loader=false;
          }
      },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
    this.action = 'save';
  }
  getOperationList()
  {
    this.userService.viewOperation('').then((data: any) => {
        if (data.status === 1) {
          this.operationData = data.data.operationData;
          // this.dtTrigger.next();
          this.rerender();
          this.loader=false;

          }

      },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
      });
  }
  async funDelete(operation_id)
  {
    await  swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        this.userService.deleteOperation(operation_id).then(async (data : any)=>{
          this.status = data.status;
          if (data.status === 1) {
            this.getOperationList();
            await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
          }
        },
        (err : HttpErrorResponse) => {
          this.isLoginError = true;
        });
      }else{
        await  swal.fire("Cancelled", "Item cannot be deleted :)", "error");
      }

    });
  }



  newdelete = false;
  onDeleteChange(i,data,event) {
    this.delele_sch = false ;
    this.delete_record = data ;
    let index = this.deleteid.indexOf(this.delete_record.operation_id);
    if (index === -1) {
        this.deleteid.push(this.delete_record.operation_id);
        this.delete_record.selected = true;
    } else {
        this.deleteid.splice(index,1);
        this.delete_record.selected = false;
    }
    console.log(this.deleteid);
  }
  async DeleteMultipleRecord(){
    await  swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        for(let i=0;i<this.deleteid.length;i++){
          this.userService.deleteOperation(this.deleteid[i]).then(async (data : any)=>{
            this.status = data.status;
            if (data.status === 1) {
              this.getOperationList();
              await swal.fire("Deleted!", "Selected Items has been deleted.", "success");
            }
          });
        }
      }else{
        await  swal.fire("Cancelled", "Items cannot be deleted :)", "error");
      }

    });



  }




  funEdit(index) {
    const editData = this.operationData[index];
    this.operation= editData.operation_name;
    this.operationId = editData.operation_id;
    this.action = 'edit';
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }

  OnSubmit() {

    if (this.loginForm.valid) {

      if(this.action === 'save'){
        this.userService.addOperation(this.loginForm.value.operation).then((data : any)=>{
            console.log(data);
            this.status= data.status;
            if (data.status === 1)

            {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getOperationList();
              this.loginForm.reset();
              this.rerender();
            }


          },
          (err: HttpErrorResponse) => {
            console.log('error');
          });
      }
      if(this.action === 'edit'){
        this.userService.editOperation(this.loginForm.value.operation , this.operationId ).then((data : any)=>{
            console.log(data);



            this.status = data.status;
            if (data.status === 1) {
              this.getOperationList();
            }
            else if (data.status === 0)

            {
              swal.fire({
                position: 'top-end',
                type: 'warning',
                title: 'Already Exists.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getOperationList();
              // this.reset();
            }
          },
          (err : HttpErrorResponse) => {
            //this.isLoginError = true;
          });
          this.rerender()
      }

      console.log(this.loginForm.value.region);
    }
    }
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
    rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
    clearfilterOption(){
        this.datatable();
    }
    datatable(){
      this.loader=true;
     this.tablevisible=1;
     this.userService.viewOperation('').then((data: any) => {
      if (data.status === 1) {
        this.operationData = data.data.operationData;
        this.rerender();
        this.loader=false;

        }

    },
    (err: HttpErrorResponse) => {
      this.isLoginError = true;
    });
    }


}
