import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { UserService } from '../user.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';



@Component({
  selector: 'app-adminviewschedule',
  templateUrl: './adminviewschedule.component.html',
  styleUrls: ['./adminviewschedule.component.scss']
})
export class AdminviewscheduleComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  serviceData: any;
  branch: any;
  usertype: any;
  token: any;
  dataTable: any;
  loader:any;
  loginForm: FormGroup;
  localTime:any;
  date:any;
  localTime1:any;
  date1:any;
  tablevisible:any;
  branchData:any;
  constructor(private userService: UserService,  private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) { 
    this.branch = localStorage.getItem('userBranch');
  }

  ngOnInit() {
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }


    this.loginForm = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],
      branch: ['',[Validators.required]]
    });

    
    this.userService.viewBranch1('').then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.branchData = data.data.branchData;

      }
    },
    (err: HttpErrorResponse) => {

    });
    
    this.tablevisible=1;
    this.loader=true;
    if(this.usertype == 1) {
      this.userService.viewCalls(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {
        });
    }
    else if(this.usertype == 2){
      this.userService.viewCallByBranch(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 3){
      this.userService.viewCallsByClientPending(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            console.log(this.serviceData);
            this.dtTrigger.next();
            this.loader=false;
          }
        },
        (err: HttpErrorResponse) => {});
    }



  }
  onChange(event)
  {
    
    this.branch = event.value;
  }

  searchrecord()
  {
    this.localTime  = moment.utc(this.loginForm.value.fromdate).toDate();
    this.date = moment(this.localTime).format('YYYY-MM-DD');

    this.localTime1  = moment.utc(this.loginForm.value.todate).toDate();
    this.date1 = moment(this.localTime1).format('YYYY-MM-DD');
    
if ((this.loginForm.value.fromdate!=undefined) && (this.loginForm.value.todate!=undefined))
{
  this.branch=this.loginForm.value.branch;
  this.userService.viewCalls_date(this.branch,this.date,this.date1).then((data: any) => {
      
    if (data.status === 1) {
   this.tablevisible=1;
      this.serviceData = data.data.serviceData;
      this.rerender()
      this.loader=false;
    }

  },
  (err: HttpErrorResponse) => {

  });
}

}

  exporttoexcel()
  {
 
    console.log(this.serviceData);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'client_list.xls', { bookType: 'xls', type: 'buffer' });


    // /* generate worksheet */
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.clientData);
    //
    // /* generate workbook and add the worksheet */
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    // /* save to file */
    // XLSX.writeFile(wb, this.fileName);
  }





  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

rerender(): void {
this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  dtInstance.destroy();
  this.dtTrigger.next();
});
}

  clearfilterOption(){
    this.datatable();
      }
    datatable(){
      this.tablevisible=1;
      this.loader=true;
      if(this.usertype == 1) {
        this.userService.viewCalls(this.branch).then((data: any) => {
            console.log(data);
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              this.rerender();
              this.loader=false;
            }
          },
          (err: HttpErrorResponse) => {
          });
      }
      else if(this.usertype == 2){
        this.userService.viewCallByBranch(this.branch).then((data: any) => {
            console.log(data);
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              this.rerender();
              this.loader=false;
            }
          },
          (err: HttpErrorResponse) => {
  
          });
      }
      else if(this.usertype == 3){
        this.userService.viewCallsByClientPending(this.branch).then((data: any) => {
            console.log(data);
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              console.log(this.serviceData);
              this.rerender();
              this.loader=false;
            }
          },
          (err: HttpErrorResponse) => {});
      }
  
    }






}
