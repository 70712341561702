import { Component, OnInit, Inject} from '@angular/core';
import { UserService} from "../user.service";
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import * as moment from 'moment';
import {MatDialog, MatDialogRef} from '@angular/material';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';

import {MAT_DIALOG_DATA} from '@angular/material';
import {Router} from '@angular/router';
@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {

  loginForm: FormGroup;
  displayview: any;
  viewalldetail: any;
  branch: any;
  serviceData: any;
  region: any;
  index: any;
  state: any;
  client: any;
  branchview: any;
  sitename: any;
  operation: any;
  freqency: any;
  servicestatus: any;
  servicedate: any;
  technician: any;
  moblie: any;
  service_id: any;
  status: any;
  frequency: any;
  mobile: any;
  userType : string;
  id: string;
  statusmodel: any;

  constructor( private userService: UserService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
               public dialogRef: MatDialogRef<ModelComponent>) {
    console.log(data);
    this.service_id = this.data.id;
    console.log(this.service_id);


  }

  statusList = [
    {"id":"1","userType":"Pending"},
    {"id":"2","userType":"Completed"}
  ];

  ngOnInit() {


    this.userService.viewCallsByDisplaying(this.service_id).then((data: any) => {
        console.log(data);
        if (data.status === 1) {

          this.serviceData = data.data.Viewservice;
          console.log(this.serviceData);
          for (let key in this.serviceData) {
            this.region = this.serviceData[key].region_name;
            this.state = this.serviceData[key].state_name;
            this.branchview = this.serviceData[key].branch_name;
            this.client = this.serviceData[key].clientname;
            this.sitename = this.serviceData[key].sitename;
            this.operation = this.serviceData[key].operation_name;
            this.freqency = this.serviceData[key].billing;
            console.log(this.freqency);

            this.servicestatus = this.serviceData[key].service_status;
            {
              console.log(this.servicestatus);
              if (this.servicestatus === "1") {
                this.statusmodel = "Pending"

              }
              else if (this.servicestatus === "2") {
                this.statusmodel = "Completed"
              }
            }
            this.servicedate = this.serviceData[key].dateoftreatment;
            this.technician = this.serviceData[key].technicianname;
            this.moblie = this.serviceData[key].mobilenumber;
            this.service_id = this.serviceData[key].service_id;
          }
        }
      },
      (err: HttpErrorResponse) => {
      });

    this.loginForm = this.formBuilder.group({
      clienttype: ['', [Validators.required]],
      region: ['', [Validators.required]],
      state: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      client: ['', [Validators.required]],
      operation: ['', [Validators.required]],
      frequency: ['', [Validators.required]],
      servicestatus: ['', [Validators.required]],
      servicedate: ['', [Validators.required]],
      technician: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*'), Validators.minLength(3), Validators.maxLength(30)])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
      sitename: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9\.]*'), Validators.minLength(3), Validators.maxLength(30)])],

    });

  }

  funCompleted() {

    this.userService.viewServicesDashboard(this.service_id,'').then((data: any) => {

        this.status = data.status;
        if (data.status === 1) {
          swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Completed.',
            showConfirmButton: false,
            timer: 1500
          })
        }

      },
      (err: HttpErrorResponse) => {
      });

    this.dialogRef.close();
  }
  funCancel(){
    this.dialogRef.close();
  }

}
