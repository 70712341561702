import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { UserService } from '../user.service';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import * as $ from "jquery";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  status:any;
  branch:any;
  buttonDisabled:any;
  alertmsg:any;
  token: any;
  usertype: any;
  schedule_data: any;
  loader: boolean;
  constructor(private userService: UserService, private formBuilder: FormBuilder) {
    this.branch = localStorage.getItem('userBranch');
    this.token = localStorage.getItem('userToken');
    this.usertype = localStorage.getItem('userType'); 
   }

  ngOnInit() {
    this.buttonDisabled = false;
    this.alertmsg=false;
    this.loader=true;
    this.userService.ViewSchedulelog(this.branch).then((data:any)=>{
      console.log(data);
      if (data.status === 1) {
        this.schedule_data = data.data.logData;
        console.log(this.schedule_data);
        this.loader=false;
      }
    },
       (err: HttpErrorResponse) => {
 
       });
  }
  funSchedule() {
    swal.fire({
      position: 'top-end',
      type: 'success',
      title: 'Saved successfully.',
      showConfirmButton: false,
      timer: 1500
    })
    this.userService.Getschedule(this.branch).then((data : any)=>{
      console.log(data);

    swal.fire({
      position: 'top-end',
      type: 'success',
      title: 'Updated successfully.',
      showConfirmButton: false,
      timer: 1500
    })

    this.status = data.status;
    if (data.status === 1) {

      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Saved successfully.',
        showConfirmButton: false,
        timer: 1500
      })
  this.buttonDisabled = false;
  this.alertmsg=false;
    }
    else if (data.status === 0)

    {
      swal.fire({
        position: 'top-end',
        type: 'warning',
        title: 'Already Exists.',
        showConfirmButton: false,
        timer: 1500
      })
  this.buttonDisabled = false;
  this.alertmsg=false;
      // this.reset();
    }


  },
  (err : HttpErrorResponse) => {

  });
}

funSchedule1() {
  // this.buttonDisabled = true;
  // this.alertmsg=true;
  swal.fire({
    position: 'top-end',
    type: 'success',
    title: 'Saved successfully.',
    showConfirmButton: false,
    timer: 1500
  })
  this.userService.Getschedule1(this.branch).then((data : any)=>{
      console.log(data);
      this.status = data.status;
      if (data.status === 1) {

        swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Saved successfully.',
          showConfirmButton: false,
          timer: 1500
        })
  this.buttonDisabled = false;
  this.alertmsg=false;
      }
      else if (data.status === 0)

      {
        swal.fire({
          position: 'top-end',
          type: 'warning',
          title: 'Already Exists.',
          showConfirmButton: false,
          timer: 1500
        })
  this.buttonDisabled = false;
  this.alertmsg=false;
        // this.reset();
      }


    },
    (err : HttpErrorResponse) => {

    });
  }
}
