import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent,dashboardsetcompleteDialog,dashboardschudleinfo } from './dashboard/dashboard.component';
// import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';


import { DataTablesModule } from 'angular-datatables';
import { AdminstudentComponent } from './adminstudent/adminstudent.component';
import { UploadscheduleComponent } from './uploadschedule/uploadschedule.component';
import { ViewscheduleComponent,setcompleteDialog } from './viewschedule/viewschedule.component';
import { ViewcompletedComponent } from './viewcompleted/viewcompleted.component';
import { CreateclientComponent } from './createclient/createclient.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ClientuploadComponent } from './clientupload/clientupload.component';
import { AdmindashboardComponent,admindashboardschudleinfo,admindashboardsetcompleteDialog } from './admindashboard/admindashboard.component';
import { ClientdashboardComponent } from './clientdashboard/clientdashboard.component';
import { RegionComponent } from './region/region.component';
import { PagerService } from './services/index';
import { StateComponent } from './state/state.component';
import { BranchComponent } from './branch/branch.component';
import { OperationComponent } from './operation/operation.component';
import { AdminuploadscheduleComponent } from './adminuploadschedule/adminuploadschedule.component';
import { AdminnewscheduleComponent } from './adminnewschedule/adminnewschedule.component';
import { AdminviewscheduleComponent } from './adminviewschedule/adminviewschedule.component';
import { AdminviewcompletedComponent } from './adminviewcompleted/adminviewcompleted.component';
import { BillingfrequencyComponent } from './billingfrequency/billingfrequency.component';
import { ClientviewscheduleComponent } from './clientviewschedule/clientviewschedule.component';
import { ClientviewcompletedComponent } from './clientviewcompleted/clientviewcompleted.component';
import { AdduserComponent } from './adduser/adduser.component';
import { AdmincreateclientComponent } from './admincreateclient/admincreateclient.component';
import { AdminclientuploadComponent } from './adminclientupload/adminclientupload.component';
import { ModelComponent } from './model/model.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddstaffsComponent } from './addstaffs/addstaffs.component';
import { AddauditscheduleComponent, viewimage } from './addauditschedule/addauditschedule.component';
import { AddbranchstaffsComponent } from './addbranchstaffs/addbranchstaffs.component';
import { AddbranchscheduleComponent } from './addbranchschedule/addbranchschedule.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AdminstudentComponent,
    UploadscheduleComponent,
    ViewscheduleComponent,
    ViewcompletedComponent,
    CreateclientComponent,
    ScheduleComponent,
    ClientuploadComponent,
    AdmindashboardComponent,
    ClientdashboardComponent,
    AdmindashboardComponent,
    RegionComponent,
    StateComponent,
    BranchComponent,
    OperationComponent,
    AdminuploadscheduleComponent,
    AdminnewscheduleComponent,
    AdminviewscheduleComponent,
    AdminviewcompletedComponent,
    BillingfrequencyComponent,
    ClientviewscheduleComponent,
    ClientviewcompletedComponent,
    AdduserComponent,
    AdmincreateclientComponent,
    AdminclientuploadComponent,
    ModelComponent,
    
    viewimage,
    setcompleteDialog,dashboardsetcompleteDialog,dashboardschudleinfo,
    admindashboardschudleinfo,admindashboardsetcompleteDialog, AddstaffsComponent, AddauditscheduleComponent, AddbranchstaffsComponent, AddbranchscheduleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,ReactiveFormsModule,
    DataTablesModule,


    // A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    NgxMatSelectSearchModule,




  ],
  providers: [PagerService],
  entryComponents: [setcompleteDialog,dashboardsetcompleteDialog,dashboardschudleinfo,viewimage,
    admindashboardschudleinfo,admindashboardsetcompleteDialog,
  ],
  // declarations: [setcompleteDialog],
  bootstrap: [AppComponent]
})
export class AppModule { }
