import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl, NgForm
} from '@angular/forms';
import { UserService} from '../user.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { PagerService } from '../services/index';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  delete_record:any;
  deleteid=[];
  public delele_sch = true;
  loginForm: FormGroup;
  loginFormErrors: any;
  status: any;
  regionData: any;
  region: any;
  regionId: any;
  action: any;
  errormessage: any;
  allItems: any;
  dataTable: any;

  constructor(private http: HttpClient, private userService: UserService,
    private formBuilder: FormBuilder, private pagerService: PagerService,
    private chRef: ChangeDetectorRef) { }

     // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  ngOnInit() {
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering:true,         
      processing: true
    }

    this.loginForm = this.formBuilder.group({
      region   : ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z 0-9]*'),  Validators.minLength(3), Validators.maxLength(30)])],
      
          });
      
          this.userService.viewRegion('').then((data: any) => {
              if (data.status === 1) {
                this.regionData = data.data.regionData;
                // set items to json response
                this.allItems =  this.regionData;
                this.chRef.detectChanges();
                const table: any = $('table');
                this.dataTable = table.DataTable();
                this.setPage(1);
              }
      
            },
            (err: HttpErrorResponse) => {
              //this.isLoginError = true;
            });
        this.action = 'save';
      
      
      
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getRegionList()
  {


    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
          // set items to json response
          this.allItems =  this.regionData;

          // initialize to page 1
          this.setPage(1);
        }

      },
      (err: HttpErrorResponse) => {
        //this.isLoginError = true;
      });
  }
  setPage(page: number) {

    this.pager = this.pagerService.getPager(this.allItems.length, page);

    this.regionData = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);

  }

  async funDelete(region_id)
  {
    swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        this.userService.deleteRegion(region_id).then(async (data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
            this.getRegionList();
            await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
          }

        },
        (err : HttpErrorResponse) => {

        });
      } else {
        await  swal.fire("Cancelled", "Item cannot be deleted :)", "error");
      }

    });


  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  funEdit(index) {
console.log(index);
    const editData = this.regionData[index];
    console.log(editData);
    console.log(editData.region_name);
     this.region = editData.region_name;
    this.regionId = editData.region_id;
    this.action = 'edit';
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }

  newdelete = false;
  onDeleteChange(i,data,event) {
    this.delele_sch = false ;
    this.delete_record = data ;
    let index = this.deleteid.indexOf(this.delete_record.region_id);
    if (index === -1) {
        this.deleteid.push(this.delete_record.region_id);
        this.delete_record.selected = true;
    } else {
        this.deleteid.splice(index,1);
        this.delete_record.selected = false;
    }
    console.log(this.deleteid);
  }
  async DeleteMultipleRecord(){

   await swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        for(let i=0;i<this.deleteid.length;i++){
          this.userService.deleteRegion(this.deleteid[i]).then(async (data : any)=>{
            this.status = data.status;
            if (data.status === 1) {
              this.getRegionList();
              await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
            }
          });
        }
      } else {
        await swal.fire("Cancelled", "Items cannot be deleted :)", "error");
      }

    });  

  }












  onSubmit() {

    if (this.loginForm.valid) {

      if(this.action === 'save'){
        this.userService.addRegion(this.loginForm.value.region).then((data : any)=>{
            console.log(data);
            this.status = data.status;

            if (this.status===0)
            {
              this.errormessage=data.message;
            }
            if (data.status === 1)

            {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getRegionList();
              //this.reset();
              this.loginForm.reset();
              this.region = '';
            }

            this.rerender()
          },
          (err: HttpErrorResponse) => {
            console.log('error');
          });
      }
      if(this.action === 'edit'){
        this.userService.editRegion(this.loginForm.value.region , this.regionId ).then((data : any)=>{
            console.log(data);



            this.status = data.status;
            if (data.status === 1) {
              this.getRegionList();
            }
            else if (data.status === 0)

            {
              swal.fire({
                position: 'top-end',
                type: 'warning',
                title: 'Already Exists.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getRegionList();
              // this.reset();
            }
          },
          (err : HttpErrorResponse) => {
            //this.isLoginError = true;
          });
      }
      this.rerender()
     console.log(this.loginForm.value.region);
    }

  }

}
