import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../user.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { Subject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-addbranchschedule',
  templateUrl: './addbranchschedule.component.html',
  styleUrls: ['./addbranchschedule.component.scss']
})
export class AddbranchscheduleComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  branch: any;
  serviceData: any;
  usertype: any;
  token: any;
  dataTable: any;
  loader:any;
  loginForm: FormGroup;
  newForm:FormGroup;
  localTime:any;
  date:any;
  localTime1:any;
  date1:any;
  tablevisible:any;
  branchData:any;
  service_id:any;
  status:any;
  selectedbranch:any='';
  selectForm='exist';
  assignedlist:any;
  userdata: any;
  userid: any;
  // clientData=[];
  clientData: any=[];
  update: boolean=false;
  scheduleid: any;
  clientid: any;
  viewDetail=false;
  noViewDetail=true;
  reporteddetail: any;
  auditedclientdetail: any='';
  environment: { production: boolean; ApiUrl: string;imgUrl: any };
  selectedclient: any;
  assignedto: any;
  schedule_info: any;
  schedule_short: any;
  scheduledate: any;
  clientname: any;
  clientaddress: any;
  clientmobile: any;
  clientemail: any;

  myControl = new FormControl();
  // options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;
  clientMail: any;
  emailData: any;
  constructor(private userService: UserService, private chRef: ChangeDetectorRef, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.environment= environment;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }

    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
    this.loginForm = this.formBuilder.group({

      scheduledate: ['', [Validators.required]],
      clientid  : ['', [Validators.required]],
      assignedto : ['', [Validators.required]],
      schedule_info : [''],
      schedule_short : ['', [Validators.required]],
    });

    this.newForm = this.formBuilder.group({
  
      scheduledate: ['', [Validators.required]],
      assignedto : ['', [Validators.required]],
      schedule_info : [''],
      schedule_short : ['', [Validators.required]],
      clientname : ['', Validators.compose([Validators.required, Validators.pattern('[-_ a-zA-Z]+$'),  Validators.minLength(3), Validators.maxLength(30)])],
      clientaddress : ['', Validators.compose([Validators.required, Validators.pattern('[-_,./a-zA-Z0-9 ]*'),   Validators.maxLength(220)])],
      clientmobile : ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
      clientemail :['', Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    });

    this.tablevisible=1;
    this.loader=true;
    this.userService.listScheduleBranch(this.branch).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.serviceData = data.data.logData;
        this.dtTrigger.next();
        this.loader=false;
        // this.rerender();
      }
    },
    (err: HttpErrorResponse) => {
  
    });
    this.userService.viewBranch1('').then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.branchData = data.data.branchData;

      }
    },
    (err: HttpErrorResponse) => {

    });
    this.userService.viewClient_branch(this.branch).then((data: any) => {
      if (data.status === 1) {
        this.clientData = data.data.clientData;
        console.log("Select Client");
        console.log(this.clientData);
      }
    },
    (err: HttpErrorResponse) => {

    });
    this.userService.getAssignedBranchStaffs(this.branch).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.assignedlist = data.data.logData;

      }
    },
    (err: HttpErrorResponse) => {

    });
    
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  
    
}


public _filter(value: any): any[] {
  const filterValue = value.toLowerCase();

  return this.clientData.filter(option => option.client_name.toLowerCase().includes(filterValue));
}
  tableDataHere(){
    this.userService.listScheduleBranch(this.branch).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.serviceData = data.data.logData;

        this.rerender();
       
      }
    },
    (err: HttpErrorResponse) => {
  
    });
  }
  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.destroy();
  //     this.dtTrigger.next();
  //   });
  // }
  fununcomplete(index) {
    const editData = this.serviceData[index];
    this.service_id = editData.service_id;
    alert(this.service_id);
    swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm!'
    }).then((result) => {

if (result.value==true)
{
      this.userService.viewServicesDashboard1(this.service_id).then((data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
       //     this.getServicesList();
            location.reload();
          }

        },
        (err : HttpErrorResponse) => {
        });
}

    })
  }
  

  newSelectEve($ev)
  {
    console.log($ev);
    this.branch = $ev;
    console.log(this.branch);
    this.userService.getAssignedBranchStaffs(this.branch).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.assignedlist = data.data.logData;

      }
    },
    (err: HttpErrorResponse) => {

    });
  }
  onAssigned($event){
    //  = event.value;
    console.log($event);
  }
 
  usersel($ev){
    console.log($ev);
    if($ev==='exist'){
      this.loginForm.reset();
    }else if($ev==='new'){
      this.newForm.reset();
    }
  }
  existUser()
  {

    console.log(this.loginForm.value);
    this.loginForm.value.scheduledate  = moment(this.loginForm.value.scheduledate).format('YYYY-MM-DD');

    console.log(this.loginForm.value.scheduledate);
      // this.branch=this.loginForm.value.branch;
      console.log( this.branch);


      
    this.userService.addAdminAudit(
      this.branch,this.loginForm.value.clientid,this.loginForm.value.assignedto,this.loginForm.value.scheduledate,this.loginForm.value.schedule_info,this.loginForm.value.schedule_short
      ).then((data: any) => {
      console.log(data);
      if (data.status === 1) {
     this.selectedbranch='';
     this.tableDataHere();
     this.loginForm.reset();
    //  this.rerender();
     swal.fire({
      position: 'top-end',
      type: 'success',
      title: 'Saved successfully.',
      showConfirmButton: false,
      timer: 1500
    })
      }
    },
    (err: HttpErrorResponse) => {

    });
 
  }
  newUser(){
    console.log(this.newForm.value);
    this.newForm.value.scheduledate  = moment(this.newForm.value.scheduledate).format('YYYY-MM-DD');
    // this.branch=this.newForm.value.branch;
    console.log( this.branch);
  this.userService.addNewAdminAudit(
    this.branch,this.newForm.value.assignedto,this.newForm.value.scheduledate,this.newForm.value.schedule_info,this.newForm.value.schedule_short,this.newForm.value.clientname,this.newForm.value.clientaddress,this.newForm.value.clientmobile,this.newForm.value.clientemail
    ).then((data: any) => {
    console.log(data);
    if (data.status === 1) {
      this.selectedbranch='';
      this.tableDataHere();
      this.newForm.reset();
      // this.rerender();
      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Saved successfully.',
        showConfirmButton: false,
        timer: 1500
      })
       }
  },
  (err: HttpErrorResponse) => {

  });
  }

  // exporttoexcel()
  // {
 

  //  // alert(this.fileName);
  //   // console.log(this.serviceData);

  //   // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);



  //      let element = document.getElementById('excel-table'); 
  //      const worksheet: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  //      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //      XLSX.writeFile(workbook, 'audit_schedule.xls', { bookType: 'xls', type: 'buffer' });
  //      /* generate workbook and add the worksheet */
  //      const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //      /* save to file */
  //     //  XLSX.writeFile(wb, this.fileName);

  //   // /* generate worksheet */
  //   // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.clientData);
  //   //
  //   // /* generate workbook and add the worksheet */
  //   // const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   //
  //   // /* save to file */
  //   // XLSX.writeFile(wb, this.fileName);
  // }

  exporttoexcel()
  {
 

    console.log(this.serviceData);

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.serviceData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, 'audit_schedule.xls', { bookType: 'xls', type: 'buffer' });
  }


  async funDelete(deleteid:any){
    console.log(deleteid)
    await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        this.userService.deleteAuditSchedule(deleteid).then(async (data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
            this.tableDataHere();
            await Swal.fire("Deleted!", "Selected Items has been deleted.", "success");
          }

        },
        async (err : HttpErrorResponse) => {
          await  Swal.fire("Cancelled", "Something went wrong ,please try again later :)", "error");
        });
      }else{
        await  Swal.fire("Cancelled", "Items cannot be deleted :)", "error");
      }

    });
  }
  sendemail(data){
    console.log(data)
    this.userService.sendEmail(this.clientMail,data.scheduleid,data.auditid).then((data: any) => {
      console.log(data)
     
    })
    swal.fire({
      position: 'top-end',
      type: 'success',
      title: 'Email sent Successfully.',
      showConfirmButton: false,
      timer: 1500
    })
  }

  viewAuditedDetail(auditedviewinfoid,id){
    console.log(auditedviewinfoid);
    this.userService.getAuditedClient(auditedviewinfoid,id).then(async (data:any)=>{
      if(data.data.logData!=null){
        this.auditedclientdetail=data.data.logData[0];

    this.userService.getAuditedReport(auditedviewinfoid).then((data:any)=>{console.log(data)
      this.reporteddetail=data.data.logData;
      this.viewDetail=true;
      this.noViewDetail=false;
      for(let i=0;i<this.reporteddetail.length;i++){
        if(this.reporteddetail[i].action_by_client=='undefined' || this.reporteddetail[i].action_by_client=='' ){
          this.reporteddetail[i].action_by_client='N/A'
        }
        if(this.reporteddetail[i].action_by_hvl=='undefined' || this.reporteddetail[i].action_by_hvl==''){
          this.reporteddetail[i].action_by_hvl='N/A'
        }
        if(this.reporteddetail[i].pest_rick=='undefined' || this.reporteddetail[i].pest_rick==''){
          this.reporteddetail[i].pest_rick='N/A'
        }
        if(this.reporteddetail[i].observation=='undefined' || this.reporteddetail[i].observation==''){
          this.reporteddetail[i].observation='N/A'
        }
        if(this.reporteddetail[i].area_desc=='undefined' || this.reporteddetail[i].area_desc==''){
          this.reporteddetail[i].area_desc='N/A'
        }
      }
    
    })}else{
      this.auditedclientdetail=data.data.logData;
      console.log(this.auditedclientdetail)
    }

    this.userService.getEmailClient(id).then(async (data:any)=>{
      console.log(data)
      this.emailData=data.data.logData;
      // this.dtTrigger.next();
      this.rerender()
      this.loader=false;
    })
    
  });
  }

  closeView(){  
    this.viewDetail=false;
    this.noViewDetail=true; }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  funEdit(viewcompleted){
    const editData1 = viewcompleted
    console.log(editData1)
    this.update=true;
    if(editData1.clientid!='0'){
      this.selectForm='exist';
      this.schedule_info=editData1.schedule_info;
      this.schedule_short=editData1.schedule_short;
      this.scheduledate=editData1.schedule_date;
      this.userid=editData1.userid;
      this.scheduleid=editData1.id;
      this.clientid=editData1.clientid
      this.selectedbranch=editData1.branch_id
      this.selectedclient=editData1.client_id
      this.assignedto=editData1.assignedto
      document.body.scrollTop = document.documentElement.scrollTop = 0;

    }
    else if(editData1.clientid=='0'){
      this.selectForm='new';
      this.userid=editData1.userid;
      this.scheduleid=editData1.id;
      this.clientid=editData1.clientid
      this.selectedbranch=editData1.branch_id
      this.assignedto=editData1.assignedto
      this.schedule_info=editData1.schedule_info;
      this.schedule_short=editData1.schedule_short;
      this.scheduledate=editData1.schedule_date;
      if(editData1.clientname==null){
        this.clientname=editData1.client_name;
      }
      else{
      this.clientname=editData1.clientname;
      }
      if(editData1.clientaddress==null){
        this.clientaddress=editData1.client_address;
      }
      else{
      this.clientaddress=editData1.clientaddress;
      }
      if(editData1.clientmobile==null){
        this.clientmobile=editData1.client_mobile;
      }
      else{
      this.clientmobile=editData1.clientmobile;
      }
      if(editData1.clientmobile==null){
        this.clientemail=editData1.client_email;
      }
      else{
      this.clientemail=editData1.clientemail;
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    
    }
  
  }

  
existUser1(){
  console.log(this.loginForm.value);
  console.log(this.userid,'+++++',this.scheduleid);

    this.loginForm.value.scheduledate  = moment(this.loginForm.value.scheduledate).format('YYYY-MM-DD');
    console.log( this.branch);
  this.userService.updateAuditReport(
    this.branch,this.userid,this.clientid,
    this.loginForm.value.assignedto,this.loginForm.value.scheduledate,
    this.loginForm.value.schedule_info,this.loginForm.value.schedule_short,this.scheduleid
    ).then((data: any) => {
    console.log(data);
    if (data.status === 1) {
      this.selectedbranch='';
      this.tableDataHere();
      this.loginForm.reset();
      // this.ngOnInit();
      this.userid='';
      this.scheduleid='';
      this.rerender();
      swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Saved successfully.',
        showConfirmButton: false,
        timer: 1500
      })
       }
  },
  (err: HttpErrorResponse) => {

  });
}

newUser1(){
  console.log(this.loginForm.value);
  console.log(this.userid,'+++++',this.scheduleid);

  console.log(this.newForm.value);
  this.newForm.value.scheduledate  = moment(this.newForm.value.scheduledate).format('YYYY-MM-DD');
  console.log( this.branch);
this.userService.updateAuditReportnew(
  this.branch,this.userid,this.clientid,
  this.newForm.value.assignedto,this.newForm.value.scheduledate,
  this.newForm.value.schedule_info,this.newForm.value.schedule_short,
  this.newForm.value.clientname, this.newForm.value.clientaddress, 
  this.newForm.value.clientmobile, this.newForm.value.clientemail,this.scheduleid,
  ).then((data: any) => {
  console.log(data);
  if (data.status === 1) {
    this.selectedbranch='';
    this.tableDataHere();
    this.newForm.reset();
    // this.ngOnInit();
    this.rerender();
    swal.fire({
      position: 'top-end',
      type: 'success',
      title: 'Saved successfully.',
      showConfirmButton: false,
      timer: 1500
    })
     }
},
(err: HttpErrorResponse) => {

});
}

rerender(): void {
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.destroy();
    this.dtTrigger.next();
  });
}

}
