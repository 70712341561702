import { Component, OnInit ,AfterViewInit, OnDestroy, ViewChild,ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import { UserService } from '../user.service';
import { Subject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-adminnewschedule',
  templateUrl: './adminnewschedule.component.html',
  styleUrls: ['./adminnewschedule.component.scss']
})
export class AdminnewscheduleComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loginForm: FormGroup;
  loginForm1: FormGroup;
  regionData: any;
  stateData: any;
  branchData: any;
  branchlists:any;
  action: any;
  status: any;
  mapData: any;
  clientData: any=[];
  operationData: any;
  localTime: any;
  date: any;
  serviceData: any;
  selected: any;
  selectedstate: any;
  selectedbranch: any;
  selectedclient: any;
  sitename:any;
  selectedoperation: any;
  servicedate : any;
  technician : any;
  mobile: any;
  clienttype: any;
  servicestatus: any;
  selectedfrequency: any;
  selectedstatus: any;
  service_id : any;
  billingData: any;
  branch : any;
  ponumber: any;
  value: any;
  userType : string;
  id: string;
  statusmodel: any;
  servicedatemodel: any;
  usertype: any;
  token: any;
  dataTable: any;
  loader:any;
  isDtInitialized:boolean = false;
  localTime1:any;
  date1:any;
  tablevisible:any;
  statusList = [
    {"id":"0","userType":"select"},
    {"id":"1","userType":"Pending"},
    {"id":"2","userType":"Completed"}
  ];
  delete_record:any;
  public delele_sch = true;
  deleteid=[];
  myControl = new FormControl();
  // options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;

  constructor(private location: Location,private userService: UserService, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef, private router: Router) {
    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');
   }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }

    this.branch = localStorage.getItem('userBranch');
    this.usertype = localStorage.getItem('userType');
    this.token = localStorage.getItem('userToken');

    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true
      this.dtTrigger.next();
    }

    this.loginForm1 = this.formBuilder.group({
      fromdate: ['', [Validators.required]],
      todate: ['', [Validators.required]],

    });
    this.loader=true;
    if (this.usertype == 1)
    {
      this.loginForm = this.formBuilder.group({
        clienttype: ['', [Validators.required]],
        region: ['', [Validators.required]],
        state: ['', [Validators.required]],
        branch: ['', [Validators.required]],
        client: ['', [Validators.required]],
        operation: ['', [Validators.required]],
        frequency: ['', [Validators.required]],
        servicestatus: ['', [Validators.required]],
        servicedate: ['', [Validators.required]],
        technician: ['', [Validators.required]],
        mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
        sitename: ['', [Validators.required]],
        ponumber:['', [Validators.required]],
        value:['', [Validators.required]]

      });
    }

    if (this.usertype == 2)
    {
      this.loginForm = this.formBuilder.group({
        clienttype: ['', [Validators.required]],
        client: ['', [Validators.required]],
        operation: ['', [Validators.required]],
        frequency: ['', [Validators.required]],
        servicestatus: ['', [Validators.required]],
        servicedate: ['', [Validators.required]],
        technician: ['', [Validators.required]],
        mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)])],
        sitename: ['', [Validators.required]],
        ponumber: ['', [Validators.required]],
        value: ['', [Validators.required]]

      });
    }

    this.action = 'save';
    this.userService.viewFrequency('').then((data: any) => {
        if (data.status === 1) {
          this.billingData = data.data.billingData;
          console.log(this.billingData);
        }


      },
      (err: HttpErrorResponse) => {

      });
    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
          console.log(this.regionData);
        }


      },
      (err: HttpErrorResponse) => {

      });
    this.userService.viewBranch('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchData = data.data.branchData;
          console.log(this.branchData);
        }
    
    //
      },
      (err: HttpErrorResponse) => {
    
      });
    
      this.userService.viewBranch('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchlists = data.data.branchData;

        }
      },
      (err: HttpErrorResponse) => {

      });  

    this.userService.viewState('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.stateData = data.data.stateData;
          console.log(this.stateData);
        }
    
    
      },
      (err: HttpErrorResponse) => {
    
      });
    this.userService.viewMapping('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.mapData = data.data.mapData;
        }
      },
      (err: HttpErrorResponse) => {

      });

    if (this.usertype==2)
    {
      this.userService.viewClient_branch(this.branch).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.clientData = data.data.clientData;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }

    this.userService.viewOperation('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.operationData = data.data.operationData;
        }
      },
      (err: HttpErrorResponse) => {

      });
     this.tablevisible=1;


    if (this.usertype == 1) {
      this.userService.viewCalls(this.token).then((data: any) => {
          console.log(data);
          this.tablevisible=1;
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
          
            this.dtTrigger.next();
            this.loader=false;

          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    else if(this.usertype == 2) {
      this.userService.viewCallByBranch(this.branch).then((data: any) => {
          console.log(data);
          this.tablevisible=1;
          if (data.status === 1) {
            this.serviceData = data.data.serviceData;
            this.dtTrigger.next();
            this.loader=false;
            }
        },
        (err: HttpErrorResponse) => {

        });
    }
  }
  regionSelect(event) {
    console.log(event.value);

    this.userService.viewStateByRegion(event.value).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.stateData = data.data.stateData;
          // this.dtTrigger.next();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true
            this.dtTrigger.next();
          }
        }
      },
      (err: HttpErrorResponse) => {

      });
  }


  stateSelect(event) {
    console.log(event.value);

    this.userService.viewBranchByState(event.value).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchData = data.data.branchData;
          // this.dtTrigger.next();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true
            this.dtTrigger.next();
          }
        }
      },
      (err: HttpErrorResponse) => {

      });
  }
  BranchSelect(event) {
  // alert(event.value);
    if (this.usertype==1)
    {
      this.userService.viewClient_branch(event.value).then((data: any) => {
          console.log(data);
          if (data.status === 1) {
            this.clientData = data.data.clientData;
          }
        },
        (err: HttpErrorResponse) => {

        });
    }
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  
    
}


public _filter(value: any): any[] {
  const filterValue = value.toLowerCase();

  return this.clientData.filter(option => option.clientcode.toLowerCase().includes(filterValue));
}



  getServicesList()
  {

  if(this.usertype == 1) {
    this.userService.viewCalls('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.serviceData = data.data.regionData;
          // this.dtTrigger.next();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true
            this.dtTrigger.next();
          }
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {

      });
  }
  else if(this.usertype == 2) {
    this.userService.viewCallByBranch(this.branch).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.serviceData = data.data.serviceData;
          this.dtTrigger.next();
          console.log(this.serviceData);
          this.loader=false;
        }
      },
      (err: HttpErrorResponse) => {

      });
  }
  }
  async funDelete(service_id) {
    await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        this.userService.deleteSerivce(service_id).then(async (data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
            this.getServicesList();
            await Swal.fire("Deleted!", "Selected Items has been deleted.", "success");
          }

        },
        async (err : HttpErrorResponse) => {
          await  Swal.fire("Cancelled", "Something went wrong ,please try again later :)", "error");
        });
      }else{
        await  Swal.fire("Cancelled", "Items cannot be deleted :)", "error");
      }

    });
  }
  funEdit(index) {
    window.scrollTo(0, 0);
    const editData = this.serviceData[index];
    console.log(editData);
    this.selected  = editData.region_id;
    this.userService.viewStateByRegion(this.selected).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.stateData = data.data.stateData;

        }
      },
      (err: HttpErrorResponse) => {

      });
    console.log(editData.state_id);
    this.selectedstate = editData.state_id;
    this.userService.viewBranchByState(this.selectedstate).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchData = data.data.branchData;

        }
      },
      (err: HttpErrorResponse) => {

      });
    
    this.selectedbranch = editData.branch_id;
    this.selectedclient = editData.clientname;
    this.sitename = editData.sitename;
    this.selectedoperation = editData.operation_id;
    this.servicedate = editData.dateoftreatment;
    this.servicedate = new Date(this.servicedate);
    this.technician = editData.technicianname;
    this.mobile = editData.mobilenumber;
    this.selectedfrequency = editData.billing_id;
    this.selectedstatus = editData.service_status;
    this.ponumber = editData.purchaseorder_no;
    this.value = editData.amount;
    this.service_id = editData.service_id;
    this.action = 'edit';
    this.clienttype = 1;
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }
  onChange(event)
  {
    // alert(event.value);
    this.branch = event.value;
  }

  searchrecord()
  {
    this.localTime  = moment.utc(this.loginForm1.value.fromdate).toDate();
    this.date = moment(this.localTime).format('YYYY-MM-DD');

    this.localTime1  = moment.utc(this.loginForm1.value.todate).toDate();
    this.date1 = moment(this.localTime1).format('YYYY-MM-DD');
 
    if ((this.loginForm1.value.fromdate!=undefined) && (this.loginForm1.value.todate!=undefined))
    {
    this.userService.viewCalls_date(this.branch,this.date,this.date1).then((data: any) => {
      console.log(data);
      if (data.status === 1) {

     this.tablevisible=1;

        this.serviceData = data.data.serviceData;

        this.rerender();

        this.loader=false;
      }
    },
    (err: HttpErrorResponse) => {

    });
  }
  }


  onSubmit() {
 
    this.localTime  = moment.utc(this.loginForm.value.servicedate).toDate();
    this.date = moment(this.localTime).format('YYYY-MM-DD HH:MM:SS');
    console.log(this.date);
    console.log(this.loginForm);


      if (this.usertype == 1)
      {
        if(this.action === 'save'){
          this.userService.addService(this.loginForm.value.clienttype, this.loginForm.value.region, this.loginForm.value.branch, this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.loginForm.value.state, this.loginForm.value.value, this.loginForm.value.ponumber ).then((data : any)=>{
              console.log(data);
              this.status= data.status;
              if (data.status === 1)

              {
                Swal.fire({
                  position: 'top-end',
                  type: 'success',
                  title: 'Saved successfully.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getServicesList();
                this.loginForm.reset();
              }


            },
            (err: HttpErrorResponse) => {
              console.log('error');
            });
        }
      }

      if (this.usertype == 2)
      {
        if(this.action === 'save'){
          this.userService.addService_user(this.loginForm.value.clienttype, this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.loginForm.value.value, this.loginForm.value.ponumber ).then((data : any)=>{
              console.log(data);
              this.status= data.status;
              if (data.status === 1)

              {
                Swal.fire({
                  position: 'top-end',
                  type: 'success',
                  title: 'Saved successfully.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getServicesList();
                this.loginForm.reset();
              }


            },
            (err: HttpErrorResponse) => {
              console.log('error');
            });
        }
      }




        if(this.action === 'edit'){

       //   alert("inn");
          if (this.usertype == 1) {
            this.userService.editService_1(this.loginForm.value.clienttype, this.loginForm.value.region, this.loginForm.value.branch, this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.loginForm.value.state, this.service_id, this.loginForm.value.value, this.loginForm.value.ponumber).then((data: any) => {
                console.log(data);


                this.status = data.status;
                if (data.status === 1) {
                  Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Updated successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.getServicesList();
                  this.router.navigate(['/createcall']);
                //  this.loginForm.reset();
                }
                else if (data.status === 0) {
                  Swal.fire({
                    position: 'top-end',
                    type: 'warning',
                    title: 'Already Exists.',
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.getServicesList();
                  this.loginForm.reset();
                }
              },
              (err: HttpErrorResponse) => {
                //this.isLoginError = true;
              });

          }

          if (this.usertype == 2)
          {
            this.userService.editService('2', this.loginForm.value.client, this.loginForm.value.operation, this.loginForm.value.frequency, this.date, this.loginForm.value.technician, this.loginForm.value.mobile, this.loginForm.value.servicestatus, this.loginForm.value.sitename, this.service_id, this.loginForm.value.value, this.loginForm.value.ponumber).then((data: any) => {
                console.log(data);


                this.status = data.status;
                if (data.status === 1) {
                  Swal.fire({
                    position: 'top-end',
                    type: 'success',
                    title: 'Updated successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                   //this.getServicesList();
                //  this.loginForm.reset();
             //   alert("test");
           //  location.reload();
                 this.router.navigate(['/viewservice']);
                  
                }
                else if (data.status === 0) {
                  Swal.fire({
                    position: 'top-end',
                    type: 'warning',
                    title: 'Already Exists.',
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.getServicesList();
                  this.router.navigate(['/createcall']);
                }
              },
              (err: HttpErrorResponse) => {
                //this.isLoginError = true;
              });
          }




        }

    //    console.log(this.loginForm.value.region);
      //}

    }




    newdelete = false;
    onDeleteChange(data,i) {
      this.delele_sch = false ;
      this.delete_record = data ;
      console.log(data);
      // this.deleteid.push(this.delete_record);
      let index = this.deleteid.indexOf(this.delete_record.service_id);
      if (index === -1) {
          this.deleteid.push(this.delete_record.service_id);
          this.delete_record.selected = true;
      } else {
          this.deleteid.splice(index,1);
          this.delete_record.selected = false;
      }
  console.log(this.deleteid);
  
    }
    async DeleteMultipleRecord(){
 
      await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if(result.value){
          for(let i=0;i<this.deleteid.length;i++){
            console.log("Delete Id of "+this.deleteid[i]);
            this.userService.deleteSerivce(this.deleteid[i]).then(async (data : any)=>{
              this.status = data.status;
              location.reload();
              this.delele_sch = true ;
              if (data.status === 1) {
                this.getServicesList();
                await Swal.fire("Deleted!", "Selected Items has been deleted.", "success");
              }
            });
          }
        }else{
          await  Swal.fire("Cancelled", "Items cannot be deleted :)", "error");
        }
  
      });

    }











    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }

rerender(): void {
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.destroy();
    this.dtTrigger.next();
  });
}

    clearfilterOption(){
      this.datatable();
        }
      datatable(){
       this.tablevisible=1;

       if (this.usertype == 1) {
        this.userService.viewCalls(this.token).then((data: any) => {
            console.log(data);
            this.tablevisible=1;
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              this.rerender();
              this.loader=false;
            }
          },
          (err: HttpErrorResponse) => {
  
          });
      }
      else if(this.usertype == 2) {
        this.userService.viewCallByBranch(this.branch).then((data: any) => {
            console.log(data);
            this.tablevisible=1;
            if (data.status === 1) {
              this.serviceData = data.data.serviceData;
              this.rerender();
              this.loader=false;
              }
          },
          (err: HttpErrorResponse) => {
  
          });
      }
      }






}
