import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { UserService} from "../user.service";
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,NgForm
} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  delete_record:any;
  deleteid=[];
  public delele_sch = true;
  loginForm: FormGroup;
  regionData: any;
  stateData: any;
  action: any;
  status: any;
  branchData: any;
  selected: any;
  selectedState: any;
  branch: any;
  branchId: any;
  dataTable: any;
  tablevisible: number;
  loader: boolean;
  constructor(private userService: UserService, private formBuilder: FormBuilder, private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering:true,         
      processing: true
    }
    this.loginForm = this.formBuilder.group({
      region   : ['', [Validators.required]],
      state   : ['', [Validators.required]],
      branch   : ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9\.]*'),  Validators.minLength(3), Validators.maxLength(30)])],

    });
    this.action = 'save';
    this.userService.viewRegion('').then((data: any) => {
        if (data.status === 1) {
          this.regionData = data.data.regionData;
          console.log(this.regionData);
        }


      },
      (err: HttpErrorResponse) => {

      });


      this.tablevisible=1;
      this.loader=true;
    this.userService.viewBranch('').then((data: any) => {
      console.log(data);
      if (data.status === 1) {
        this.branchData = data.data.branchData;

        this.dtTrigger.next();
        this.loader=false;
      }
    });

    };
    
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
  clearPage(){
    alert('test');
  }
  async funDelete(branch_id) {
    await  swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        this.userService.deleteBranch(branch_id).then(async (data : any)=>{

          this.status = data.status;
          if (data.status === 1) {
            this.getBranchList();
            await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
          }

        },
        (err : HttpErrorResponse) => {
        });
      } else{
        await  swal.fire("Cancelled", "Item cannot be deleted :)", "error");
      }

      });
  }
  regionSelect(event) {
    console.log(event);

    this.userService.viewStateByRegion(event).then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.stateData = data.data.stateData;

        }
      },
      (err: HttpErrorResponse) => {

      });
  }

  funEdit(index) {
    const editData = this.branchData[index];
    this.selected  = editData.region_id;
    this.selectedState = editData.state_id;
    this.branch = editData.branch_name;
    this.branchId = editData.branch_id;
    this.action = 'edit';
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }
  getBranchList() {
    this.userService.viewBranch('').then((data: any) => {
        console.log(data);
        if (data.status === 1) {
          this.branchData = data.data.branchData;
          console.log(this.branchData);
        }


      },
      (err: HttpErrorResponse) => {

      });

  }


  newdelete = false;
  onDeleteChange(i,data,event) {
    this.delele_sch = false ;
    this.delete_record = data ;
    let index = this.deleteid.indexOf(this.delete_record.branch_id);
    if (index === -1) {
        this.deleteid.push(this.delete_record.branch_id);
        this.delete_record.selected = true;
    } else {
        this.deleteid.splice(index,1);
        this.delete_record.selected = false;
    }
    console.log(this.deleteid);
  }
  async DeleteMultipleRecord(){
    await swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if(result.value){
        for(let i=0;i<this.deleteid.length;i++){
          this.userService.deleteBranch(this.deleteid[i]).then(async (data : any)=>{
            this.status = data.status;
            if (data.status === 1) {
              this.getBranchList();
              await swal.fire("Deleted!", "Selected Item has been deleted.", "success");
            }
    
          });
        }
      } else{
        await  swal.fire("Cancelled", "Item cannot be deleted :)", "error");
      }

      });
  }





  onSubmit() {
    console.log(this.loginForm);
    if (this.loginForm.valid) {
      console.log(this.loginForm.value.region);
      if (this.action === 'save') {

        this.userService.addBranch(this.loginForm.value.region, this.loginForm.value.state, this.loginForm.value.branch).then((data : any)=>{
            console.log(data);
            this.status = data.status;
            if (data.status === 1) {
              swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'Saved successfully.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getBranchList();
              this.loginForm.reset();
            }


          },
          (err: HttpErrorResponse) => {
            console.log('error');
          });
      }
        if (this.action === 'edit') {
        alert( this.branchId );
          this.userService.editBranch(this.loginForm.value.branch , this.branchId , this.loginForm.value.state, this.loginForm.value.region ).then((data : any)=>{
              console.log(data);
              this.status = data.status;
              if (data.status === 1) {
                this.getBranchList();
              }
              else if (data.status === 0)

              {
                swal.fire({
                  position: 'top-end',
                  type: 'warning',
                  title: 'Already Exists.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getBranchList();
                // this.reset();
              }

            },
            (err : HttpErrorResponse) => {
            });
        }

        console.log(this.loginForm.value.region);
      }

    }



}
